import React from 'react';
import styles from './button.module.sass';

const Button = ({ label, price, icon, onClick, disabled, styles: customStyles, primary, size, type, active }) => {
	return (
		// if type is toggle, add the type-toggle class and toggle the active class on click
		<button
			className={`button ${customStyles || ''} ${primary ? 'primary' : ''} ${disabled ? 'disabled' : ''} ${size ? `size-${size}` : ''} ${type ? `type-${type}` : ''} ${active ? 'active' : ''}`}
			onClick={onClick}
			disabled={disabled}
		>
			{icon && <div className="buttonIcon">{icon}</div>}
			{(label || price) && <div className="content">
				{label && <div className="label">{label}</div>}
				{price && <div className="price">{price}</div>}
			</div>}
		</button>
	);
};

export default Button;