import React from 'react';
import styles from './background-loader.module.sass';

const BackgroundLoader = ({ message, progress }) => {
  return (
    <div className={styles.loader}>
      {message && <p>{message}</p>}
      {typeof progress === 'number' && (
        <div className={styles.progressContainer}>
          <div
            className={styles.progressBar}
            style={{ width: `${progress}%` }}
          ></div>
          <div className={styles.progressText}>{progress}%</div>
        </div>
      )}
    </div>
  );
};

export default BackgroundLoader;
