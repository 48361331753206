import React from 'react';
import classnames from 'classnames';
import { ArrowLeftFromLine, ArrowRightFromLine, Maximize2 } from 'lucide-react';
import Button from '../common/button';



import { FileIcon, LayoutIcon, SquareIcon } from './icons.jsx';

import styles from './view-mode-controls.module.sass';
import {
  useBubblesActions,
  useBubblesState,
} from '../../store/hooks/use-bubbles';

const ViewModeControls = () => {
  const {
    focusedBubble,
  } = useBubblesState();
  const { setViewMode } = useBubblesActions();

  const { viewMode, documentBubbles, canvasBubbles } = focusedBubble;

  const hasCanvasBubbles = focusedBubble.canvasChildren.length > 0;
  const hasDocumentBubbles = focusedBubble.documentChildren.length > 0;

  //console.log(focusedBubble);

  return (
    <div className={styles.viewModes}>
      {/* if viewmode document, "Show Canvas" */}
      {viewMode === 'document' && (
        <Button
          label="Show canvas"
          icon={<ArrowLeftFromLine />}
          onClick={() => setViewMode('hybrid')}
          size="small"
        />
      )}
      {/* if viewmode hybrid, "Hide Document" */}
      {viewMode === 'hybrid' && (
        <>
          <Button
            label="Hide document"
            icon={<ArrowLeftFromLine />}
            onClick={() => setViewMode('canvas')}
            size="small"
          />
          <Button
            label="Expand document"
            icon={<Maximize2 />}
            onClick={() => setViewMode('document')}
            size="small"
          />
        </>
      )}
    </div>
  );
};

export default ViewModeControls;
