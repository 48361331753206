import {
	useBubblesActions,
	useBubblesState,
} from '../../store/hooks/use-bubbles';

//import { getNodeFromPlainText } from './document/prosemirror-utils';

import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/bubbles.slice';
import api from '../../api';
import { useState } from 'react';
import { AllowedFileTypes, getCursorPosition } from '../../utils';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'


const COPIED_BUBBLES_TYPE = 'copied-bubbles';
const COPY_KEY = 'c';
const PASTE_KEY = 'v';
const CUT_KEY = 'x';

const allowedFileTypes = Object.values(AllowedFileTypes)
const checkCombination = (e, key) => {
	const charCode = String.fromCharCode(e.keyCode).toLowerCase();

	return (e.metaKey || e.ctrlKey) && charCode === key;
};

const useClipboard = () => {
	const dispatch = useDispatch();
	const { selectedBubbles, focusedBubble, focusedBubbleId, bubbles } = useBubblesState();
	const { addCanvasBubble, addDocumentBubble, removeSelectedCanvasBubbles, removeParentId, loadBubbles } =
		useBubblesActions();
	const [isDraggingOverTarget, setIsDraggingOverTarget] = useState(false);
	const [isUnsupportedFile, setIsUnsupportedFile] = useState(false);


	const copySelectedBubbles = ({ cut = false } = {}) => {
		const clipboardContent = JSON.stringify({
			type: COPIED_BUBBLES_TYPE,
			sourceCanvas: focusedBubbleId,
			copiedBubbles: selectedBubbles.map(
				(index) => focusedBubble.canvasChildren[index],
			),
		});

		const onSuccess = () => {
			if (cut) {
				removeSelectedCanvasBubbles();
			}
		};

		const onFailure = (e) => console.error('Failed to copy: ', e);

		navigator.clipboard.writeText(clipboardContent).then(onSuccess, onFailure);
	};

	const handleCopyPaste = async (e) => {
		if (!(e?.target?.classList?.contains('text-input'))) {
			if (checkCombination(e, COPY_KEY)) {
				handleCopy();
			}

			if (checkCombination(e, PASTE_KEY)) {
				handlePaste(e);
			}

			if (checkCombination(e, CUT_KEY)) {
				handleCut();
			}
		}
	};

	const handleCopy = () => {
		if (selectedBubbles?.length) {
			copySelectedBubbles();
		}
	};

	const handlePaste = async (e) => {
		if (document.activeElement.classList.contains("ProseMirror")) {
			return;
		}

		navigator.clipboard.readText().then(async (clipText) => {
			try {
				const clipboardContent = JSON.parse(clipText);
				if (clipboardContent?.type === COPIED_BUBBLES_TYPE) {
					e.stopPropagation();
					handleParsedBubblesPaste(clipboardContent);
				}
			} catch (err) {
				handleNonJsonPaste(e, clipText);
			}
		});
	};

	const handleParsedBubblesPaste = (clipboardContent) => {
		const { sourceCanvas, copiedBubbles } = clipboardContent;
		const locationModifier =
			sourceCanvas === focusedBubbleId ? { x: 50, y: 50 } : { x: 0, y: 0 };
		copiedBubbles.forEach(({ id, location, dimensions }) => {
			addCanvasBubble({
				id,
				location: {
					x: location.x + locationModifier.x,
					y: location.y + locationModifier.y,
				},
				dimensions,
			});
		});
	};

	const handleNonJsonPaste = async (e, clipText) => {
		if (typeof clipText === 'string' && clipText.length > 0) {
			e.stopPropagation();
			handleTextPaste(clipText);
		} else {
			handleNonTextPaste();
		}
	};

	const handleTextPaste = (clipText) => {
		console.log('SADQWEWQE	')
		const canvasBubbleId = uuidv4();
		const docBubbleId = uuidv4();
		const bubbleName = JSON.stringify({ id: docBubbleId, type: 'paragraph', content: [{ type: 'text', text: clipText.trim() }] });
		addDocumentBubble({
			parentBubbleId: canvasBubbleId,
			properties: { id: docBubbleId, name: bubbleName, isAutoResizeOnCreate: true },
		});
		setTimeout(() => {
			addCanvasBubble({
				id: canvasBubbleId,
				properties: { documentChildren: [docBubbleId] },
			});
		}, 50)
	};

	const handleNonTextPaste = async () => {
		const clipboardItems = await navigator.clipboard.read();

		for (const item of clipboardItems) {
			if (
				item.types.includes('image/png') ||
				item.types.includes('image/jpeg') ||
				item.types.includes('application/pdf')
			) {
				handleImagePaste(item);
			}
		}
	};

	const handleImagePaste = async (item) => {
		const blob =
			(await item.getType('image/png')) || (await item.getType('image/jpeg'));
		const reader = new FileReader();
		reader.onload = () => {
			api.handleImageUpload({ imageContent: reader.result }).then((res) => {
				dispatch(
					actions.addCanvasBubble({
						properties: {
							imageContent: res.url,
							viewMode: 'document',
						},
					}),
				);
			});

		};
		reader.readAsDataURL(blob);
	};

	const handleBubbleDrop = async (bubbleId, location) => {
		let parentBubbleId = bubbleId;
		const localBubble = bubbles?.find?.((bubble) => bubble.id === bubbleId);

		if (!localBubble) {
			try {
				await loadBubbles({ newFocusedBubbleId: bubbleId, isInitialLoad: true })
			} catch (error) {
				console.error("Error fetching bubble from DB:", error);
				return;
			}
		}

		if (parentBubbleId === focusedBubbleId) {
			return;
		}

		dispatch(
			actions.addCanvasBubble({
				id: parentBubbleId,
				properties: {},
				location,
			})
		);
	};

	const handleDrop = async (event) => {
		event.preventDefault();
		setIsDraggingOverTarget(false);
		const { x: canvasX, y: canvasY } = getCursorPosition(event, event.target);
		const items = event.dataTransfer.items;
		const bubbleId = event.dataTransfer.getData("bubbleId");
		if (bubbleId) {
			handleBubbleDrop(bubbleId, { x: canvasX, y: canvasY });
			return;
		}

		for (const item of items) {
			if (item.kind === 'file' && allowedFileTypes.includes(item.type)) {
				const file = item.getAsFile();
				if (file) {
					if (file.type === 'application/pdf') {
						await handlePdfDrop(file, { x: canvasX, y: canvasY });
					} else if (file.type.startsWith('image/')) {
						await handleImageDrop(file, { x: canvasX, y: canvasY });
					}
				}
			}
		}
	};


	const handleImageDrop = async (file, location) => {
		try {
			dispatch(actions.setLoading(true));
			dispatch(actions.setUploadProgress(0));

			const reader = new FileReader();

			reader.onloadstart = () => {
				dispatch(actions.setUploadProgress(10));
			};

			reader.onload = async () => {
				dispatch(actions.setUploadProgress(40));

				const imageContent = reader.result;




				const res = await api.handleImageUpload({ imageContent });

				dispatch(actions.setUploadProgress(100)); // Upload complete

				setTimeout(() => {
					dispatch(
						actions.addCanvasBubble({
							properties: {
								imageContent: res.url,
								viewMode: 'document',
							},
							location,
						})
					);
					dispatch(actions.setLoading(false));
				}, 300);

			};

			reader.readAsDataURL(file);
		} catch (error) {
			console.error("Failed to handle image drop:", error);
			dispatch(actions.setLoading(false));
		}
	};
	const handlePdfDrop = async (file, location) => {
		try {
			dispatch(actions.setLoading(true));
			dispatch(actions.setUploadProgress(0));
			const reader = new FileReader();
			const id = uuidv4();
			reader.onloadstart = () => {
				dispatch(actions.setUploadProgress(10));
			};

			reader.onload = async () => {
				dispatch(actions.setUploadProgress(40));

				const pdfContent = reader.result;

				const streamReader = await api.handlePDFUpload({ imageContent: pdfContent });
				let gotUrl = false;
				let gotDone = false;

				let result = '';
				while (true) {
					const { done, value } = await streamReader.read();
					if (done) break;

					const stringin = new TextDecoder().decode(value).split('␀');
					stringin.forEach((s) => {
						if (!gotUrl) {
							gotUrl = true;
							const url = s
							dispatch(actions.setUploadProgress(100));

							setTimeout(() => {
								dispatch(
									actions.addCanvasBubble({
										id,
										properties: {
											pdfContent: url,
											name: `{"id":"${id}","type":"paragraph","props":{"textColor":"default","backgroundColor":"default","textAlignment":"left"},"content":[{"type":"text","text":"${file.name}","styles":{}}],"children":[]}`,
											viewMode: 'document',
										},
										location,
									})
								);
								dispatch(actions.setLoading(false));
								dispatch(actions.setUploadProgress(0));
								dispatch(actions.setPDFIndexing(true));

							}, 300);
						}
						else if (gotDone) {
							dispatch(
								actions.updateBubble({
									id,
									properties: {
										pdfSummary: s.trim(),
										viewMode: 'document',
									},
									location,
									forceUpdate: true
								})
							);
							setTimeout(() => {
								dispatch(actions.setPDFIndexing(false));
							}, 350);
						}
						else {
							const incoming = s;
							if (incoming.includes("__DONE__")) {
								gotDone = true;
							}
							else {
								dispatch(actions.setPDFIndexing(true));
								if(Number(incoming.trim()) != 0) dispatch(actions.setUploadProgress(Number(incoming.trim())));
							}

						}
					})

				}

			};

			reader.readAsDataURL(file);
		} catch (error) {
			console.error("Failed to handle PDF drop:", error);
			dispatch(actions.setLoading(false));
		}
	};

	const handleDragOver = (event) => {
		event.preventDefault();

		const items = event.dataTransfer.items;

		const hasUnsupportedFile = Array.from(items).some(
			(item) => item.kind === "file" && !allowedFileTypes.includes(item.type)
		);

		//needed for BlockNote blocks
		const hasBlockNoteBlock = Array.from(items).some(
			(item) => item.type == 'blocknote/html'
		);

		if (hasBlockNoteBlock) {
			setIsUnsupportedFile(false);
			setIsDraggingOverTarget(false);
			return;
		};

		setIsDraggingOverTarget(true);

		setIsUnsupportedFile(hasUnsupportedFile)
		if (hasUnsupportedFile) {
			event.dataTransfer.dropEffect = "none";
		}
	};

	const handleDragLeave = () => {
		setIsDraggingOverTarget(false);
		setIsUnsupportedFile(false)
	};
	const handleCut = () => {
		if (selectedBubbles?.length && focusedBubble?.canvasChildren) {
			const focusedBubble = bubbles.find((b) => b.id === focusedBubbleId);

			const selectedBubbleIds = selectedBubbles.map(
				(index) => focusedBubble.canvasChildren[index].id,
			);
			if (selectedBubbleIds.length > 0) {
				removeParentId({
					bubbleIds: selectedBubbleIds,
					parentId: focusedBubbleId,
				})
			};

			copySelectedBubbles({ cut: true });
		}
	};

	return {
		handleCopyPaste,
		handleDrop,
		handleDragOver,
		handleDragLeave,
		isDraggingOverTarget,
		isUnsupportedFile
	};
};

export default useClipboard;
