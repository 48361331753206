import React from 'react';
import Header from '../header/header';
import styles from './welcome.module.sass';
import { useAppState } from '../../store/hooks/use-app';
import BackgroundGenerator from '../common/background-generator';
import Button from '../common/button';
import { MonitorPlay, Info, Notebook, PictureInPicture2, Sparkles } from "lucide-react";
import {useBubblesActions,useBubblesState,} from '../../store/hooks/use-bubbles';
import { getBubbleName } from '../main-view/document/document-func';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const { user } = useAppState();
  const [isWelcomeHidden, setIsWelcomeHidden] = React.useState(false);
  const hasBubbles = true;
  const {
    stepIn,
  } = useBubblesActions();
  const navigate = useNavigate();


  return (
    <>
      <Header />
      {/* <BackgroundGenerator /> */}
      <div className={styles.home}>
        <div className={`${styles.welcome} ${isWelcomeHidden ? styles.hidden : ''}`}>
          <div className={styles.content}>
            <h1>Welcome to Bubbles!</h1>

            <p><em>The goal of Bubbles is to make it easy to capture and explore your thoughts and ideas.</em></p>

            <p>What the users of Bubbles have told us so far:</p>
            <ul>
              <li>"Jotting down thoughts without having to worry about organizing them is great"</li>
              <li>"Bubbles helped me discover thoughts I didn't know I had"</li>
            </ul>


            <p><em>Here are some tips for getting started:</em></p>
            <ul>
              <li>Click "+ New Workspace" to create a new view</li>
              <li>Start by jotting down a few thoughts</li>
              <li>Reflect on your thoughts by asking questions about the Current workspace</li>
            </ul>

            <p>Bubbles can be copied, linked and cut with the usual keyboard shortcuts - even images can be added.</p>

            <p>Questions, feedback and ideas can be sent to me via <a href="https://x.com/tvirtanen_" target="_blank" rel="noreferrer">Twitter</a> or <a href="https://linkedin.com/in/timovirtanen1" target="_blank" rel="noreferrer">LinkedIn</a>.</p>

            {/* <div className={styles.buttons}>
              <Button icon={<MonitorPlay />} primary label="Katso video" />
            </div> */}

            <div className={styles.creatorProfile}>
              <img src="https://timovirtanen.fi/bubbles-creator.jpg" alt="" />
              <p>
                Best regards, <br />
                <span style={{ fontWeight: '600' }}>Timo Virtanen</span> <br />
                <a href="https://x.com/tvirtanen_" target="_blank" rel="noreferrer">Twitter / X</a>, &nbsp;
                <a href="https://linkedin.com/in/timovirtanen1" target="_blank" rel="noreferrer">LinkedIn</a>
              </p>
            </div>

          </div>
        </div>
      </div>
          {/* <div className={styles.module}>
            <h3>On this day</h3>
            <div className={styles.bubbles}>
              <div className={styles.bubble}>
                <div className={styles.bubbleHeader}>Bubble title</div>
                <div className={styles.bubbleContent}>
                  <p>Bubble content</p>
                </div>
              </div>
            </div>
          </div> */}
      {/* <div className={styles.randomBubble}> 
        <Button icon={<Sparkles />} primary label="Take me to a random Bubble" />
      </div> */}
    </>
  );
};

export default Welcome;