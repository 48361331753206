import React, { useState, useRef, useEffect } from 'react';
import { BASE_URL } from '../../config';
import styles from './header.module.sass';
import { useAppState, useAppActions } from '../../store/hooks/use-app';
import { useBubblesActions } from '../../store/hooks/use-bubbles';
import Button from '../common/button';
import { Eraser } from 'lucide-react';

const parseName = (name) => {
  try {
    return JSON.parse(name).content.map((content) => content.text);
  } catch {
    return [name];
  }
};

export const Mheader = () => {
  const [profileSettings, setProfileSettings] = useState(false);
  const profileSettingsRef = useRef(null);

  const { loggedIn, user, darkMode } = useAppState();
  const { setDarkMode } = useAppActions();
  const { signOutUser } = useBubblesActions();

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  return (
    <header>
      <div className={styles.leftSection}>
        {loggedIn && (
          <Button
            className={styles.clearScratchpad}
            onClick={() => {
              window.dispatchEvent(new CustomEvent("ClearScratch"));
            }}
            type="Primary"
            label="Clear scratchpad"
            icon={<Eraser/>}
          >
          </Button>
        )}
      </div>
      <div className={styles.searchAndProfile}>
        {loggedIn ? (
          <div className={styles.profileName} onClick={() => setProfileSettings(!profileSettings)}>
            <span className={styles.name}>{user?.name?.charAt?.(0)}</span>
            {profileSettings && (
              <ul className={styles.profileSettings} ref={profileSettingsRef}>
                <li onClick={() => navigate('/welcome')}>Welcome</li>
                <li>
                  <div
                    className={styles.toggle}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDarkMode(!darkMode);
                    }}
                  >
                    <label>Light</label>
                    <div
                      className={`${styles.toggleButton} ${darkMode ? styles.option1 : styles.option2}`}
                    />
                    <label>Dark</label>
                  </div>
                </li>
                <li onClick={signOutUser}>Sign out</li>
              </ul>
            )}
          </div>
        ) : (
          <div className={styles.sign}>
            <a href={`${BASE_URL}/auth/login/federated/google`}>Sign in</a>
          </div>
        )}
      </div>
    </header>
  );
};