import React, { useState, useEffect, useRef } from 'react';
import styles from './dropdown.module.sass';
import { ChevronDown } from 'lucide-react';


const Dropdown = ({ trigger, options, onSelect, disabled }) => {
	const [open, setOpen] = useState(false);
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className={styles.dropdown} ref={dropdownRef}>
			<div
                className={`${styles.dropdownTrigger} ${disabled ? styles.disabled : ''}`}
                onClick={() => {
                    if (!disabled) {
                        setOpen(!open);
                    }
                }}
            >
				{trigger}
				<ChevronDown size={12} />
			</div>
			{open && (
			    <div className={styles.dropdownContent}>
				    {options.map((option) => (
					    <div key={option.value} className={styles.dropdownOption} onClick={() => onSelect(option)}>
						    {option.label}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Dropdown;