import React from 'react';
import Header from '../header/header';
import Button from '../common/button';
import styles from './subscription.module.sass';
import { useAppState } from '../../store/hooks/use-app';
import { ShoppingCart, MessageCircle } from 'lucide-react';

const handleCheckoutClick = async (priceId) => {
  const response = await fetch(`${process.env.BASE_URL}/create-checkout-session`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ priceId }),
  });

  const { url } = await response.json();
  window.location.href = url; // Redirect to Stripe Checkout
};


const Subscription = () => {
  const { user } = useAppState();
  return (
    <>
      <Header />
      <div className={styles.subscription}>
        <h1>Upgrade your Bubbles account.<br />Purchase a subscription.</h1>
        <div className={styles.benefits}>
          <div className={styles.group}>
            <p>Personal onboarding</p>
            <p>Unlimited AI usage</p>
            <p>Lightning fast personal search</p>
          </div>
          <div className={styles.group}>
            <p>Integration with Signal Messenger</p>
            <p>Shareable workspaces</p>
            <p>Priority support</p>
          </div>
          <div className={styles.group}>
            <p>Storage for all your documents</p>
            <p style={{ fontStyle: 'italic' }}>And more...</p>
          </div>
        </div>
        <div className={styles.cta}>
          <span className={styles.indicator} />
          <Button
            label="Subscribe"
            price="4.90€/month"
            icon={<ShoppingCart />}
            onClick={() => window.location.href = 'https://buy.stripe.com/4gw28m098aeSfEQ3cc'}
            styles={styles.subscribeButton}
          />
        </div>
        {/* <div className={styles.cta}>
          <span className={styles.indicator} />
          <Button
            label="Consultation"
            price="199€"
            icon={<MessageCircle />}
            onClick={() => handleCheckoutClick('price_1QFuPhP6PWeWt9q1M7PUbPow')}
            styles={styles.subscribeButton}
          />
        </div> */}
      </div>
    </>
  );
};

export default Subscription;
