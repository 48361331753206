import React, { useEffect, useRef } from 'react';
import styles from './scratchpad.module.sass';
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { FormattingToolbar, FormattingToolbarController, useCreateBlockNote } from "@blocknote/react";
import * as Y from "yjs";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { loadScratchboardId } from '../../api';
import { useBubblesActions, useBubblesState } from '../../store/hooks/use-bubbles';
import { BASE_WS } from '../../config';
import { v4 as uuidv4 } from 'uuid';
import { isMobileDevice } from '../../App';

const doc = new Y.Doc();





const Scratchpad = () => {
  const providerRef = useRef(null)

  useEffect(() => {
    loadScratchboardId().then(({ id }) => {
      providerRef.current = new HocuspocusProvider({
        url: BASE_WS,
        name: id,
        document: doc,
        token: 'user'
      });
    })
  }, [])
  return (
    <div className={styles.scratchpad} id='scratchpad' style={isMobileDevice() ? {} : {marginTop: '0.5rem'}}>
      {providerRef != null ? <EditorCollab provider={providerRef.current}></EditorCollab> : <p>Loading...</p>}
    </div>
  );
};

const EditorCollab = (props) => {
    const {
      bubbles,
    } = useBubblesState();
    const {
      addDocumentBubble,
      addCanvasBubble,
    } = useBubblesActions();
  let initialBlock = undefined;

  const dragStart = () => {
    initialBlock = editor.prosemirrorView.dom.getElementsByClassName("ProseMirror-selectednode")[0];
  }

  const onDrag = async (e) => {
    const baseBlock = editor.prosemirrorView.dom.getElementsByClassName("ProseMirror-selectednode")[0];
    if (baseBlock == undefined) {
      if (initialBlock != undefined) {
        baseBlock = initialBlock;
      }
      else {
        return;
      }
    }

    let block = undefined;
  
      block = editor.getBlock(baseBlock.getAttribute("data-id"))

    //block[0].id = baseBlock.getAttribute("data-id");
    const canvasID = uuidv4()



    if (document.querySelectorAll("#scratchpad:hover").length > 0) {
      return
    }
    else {
      const bounds = document.getElementById("canvas").getBoundingClientRect()
      addCanvasBubble({
        id: canvasID,
        location: {
          x: e.clientX - bounds.left,
          y: e.clientY - bounds.top
        }
      })
      addDocumentBubble({
        id: block.id,
        parentBubbleId: canvasID,
        properties: {
          name: JSON.stringify(block),
        }
      })

      editor.removeBlocks([baseBlock.getAttribute("data-id")])
      initialBlock = undefined
    }
  }


  useEffect(() => {
    window.addEventListener("ClearScratch", (e) => {
      const allBlocks = editor.document.reduce((r, b) => { r.push(b.id); return r; }, []);
      editor.removeBlocks(allBlocks)
    })
  }, [])
  const editor = useCreateBlockNote({ sideMenuDetection: 'editor', collaboration: { provider: props.provider, fragment: doc.getXmlFragment('document-store'), user: { name: Math.round(Math.random() * 1000), color: '#00ff00' } } });
  return (
    <div className={styles.scratchpadContent}>
      <BlockNoteView editor={editor} formattingToolbar={false} onDragStart={dragStart} onDragEnd={onDrag} theme={'light'}>
        {isMobileDevice() ? <div className={styles.mobileFormattingBar}>
          <FormattingToolbar/>
        </div> : <FormattingToolbarController>
          <FormattingToolbar/>
          </FormattingToolbarController>}
      </BlockNoteView>
    </div>)
}

export default Scratchpad;
