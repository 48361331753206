import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './document.module.sass';
import { useAppState } from '../../../store/hooks/use-app';

import {
	useBubblesActions,
	useBubblesState,
} from '../../../store/hooks/use-bubbles';

import { v4 as uuidv4 } from 'uuid';

import { useCookies } from 'react-cookie';

import { ImSpinner4 } from "react-icons/im";

import 'react-pdf/src/Page/TextLayer.css';
import 'react-pdf/src/Page/AnnotationLayer.css';


import {
	BlockNoteSchema,
	defaultBlockSpecs,
	defaultInlineContentSpecs,
	filterSuggestionItems,
	insertOrUpdateBlock,
} from "@blocknote/core";
import {
	useCreateBlockNote, SuggestionMenuController, getDefaultReactSlashMenuItems, SideMenuController, DragHandleButton, ResizeHandlesWrapper, BasicTextStyleButton,
	BlockTypeSelect,
	ColorStyleButton,
	CreateLinkButton,
	FileCaptionButton,
	FileReplaceButton,
	FormattingToolbar,
	FormattingToolbarController,
	NestBlockButton,
	TextAlignButton,
	UnnestBlockButton,
} from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";
import { AIContent } from './ask-menu';
import ViewModeControls from '../view-mode-controls';
import { CheckListItem } from './checkList';

import { LuSparkles, LuListChecks } from "react-icons/lu";
import { TextSelection } from 'prosemirror-state';
import { checkListAliases } from '../../../utils/enums'
import { property, size } from 'lodash';
import { X } from 'lucide-react';
import { bubbleLink, BubbleLinkButton, getBubbleLinkItem } from './block-link';
import { BASE_URL } from '../../../config';
import { Document as PdfDocument, Page as PdfPage,pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const insertAIcontent = (editor) => ({
	title: "AI Content",
	onItemClick: () => {
		insertOrUpdateBlock(editor, {
			type: "ai",
		});
	},
	aliases: [
		"chatgpt",
		"ai",
		"image",
		"chat",
		"text",
		"ask"
	],
	group: "AI",
	icon: <LuSparkles />,
});

const insertCheckListItem = (editor, query) => {

	const normalizedQuery = query.toLowerCase().trim();

	const matchedKey =
		Object.keys(checkListAliases).find((key) =>
			key.toLowerCase().startsWith(normalizedQuery)
		) || "CHECKLIST";

	return {
		title: matchedKey[0].toUpperCase() + matchedKey.toLowerCase().substring(1),
		onItemClick: () => {
			insertOrUpdateBlock(editor, {
				type: "checkListItem",
				props: { }
			});
		},
		aliases: Object.values(checkListAliases),
		group: "Task Management",
		icon: <LuListChecks />,
		description: "List with checkboxes",
	};
};


const buildDocument = ({ parentId, documentChildren, bubbles }) => {
	// go through document children and their children
	// parse the tree and add them as nodes accordingly
	const nodes = documentChildren.reduce((res, bubbleId) => {
		const bubble = bubbles.find((b) => b.id === bubbleId);
		if (bubble?.name?.length > 0 && JSON.parse(bubble.name).content) {
			const form = JSON.parse(bubble.name);
			for (let i = 0; i < form.content.length; i++) {
				if (typeof (form.content) != 'string' && !form.content[i].hasOwnProperty("styles")) {
					form.content[i].styles = {};
				}
			}
			res.push(form)
		}
		return res;
	}, [])
	if (nodes.length == 0) nodes.push({ id: documentChildren[0], type: 'paragraph', content: '' })
	return nodes;
};

const Document = ({
	bubble,
	parentCanvasIndex,
	canvasParentId,
	className,
	type = 'full',
	updateCount,
	dimensions,
	isChat = false,
	sizeExpander,
	MaxAutoWidth,
	MaxAutoHeight,
	focusedCanvasBubbleId,
	hasResized,
	canvasFocus,
}) => {
	const { documentChildren, id: parentBubbleId } = bubble;
	const aiSchema = BlockNoteSchema.create({
		blockSpecs: {
			// Adds all default blocks.
			...defaultBlockSpecs,
			// Adds the Alert block.
			ai: !isChat ? AIContent : undefined,
			image: undefined,
			file: undefined,
			video: undefined,
			audio: undefined,
		},
		inlineContentSpecs: {
			...defaultInlineContentSpecs,
			bubbleLink: bubbleLink,
		}
	});
	const pdfContainerRef = useRef(null);
	const [numPages, setNumPages] = useState(null);
  	const [containerWidth, setContainerWidth] = useState(null);
	const {
		bubbles,
		focusedBubble,

	} = useBubblesState();
	const {
		addDocumentBubble,
		addCanvasBubble,
		updateDocumentBubble,
		moveDocumentBubble,
		removeDocumentBubbles,
		updateCanvasBubbleProperties,
		updateBubble,
		syncBubbles,
		setViewMode
	} = useBubblesActions();

	const [initialDoc, setInitialDoc] = useState(() => {
		if (bubble.id) {
			return buildDocument({
				parentId: bubble.id,
				documentChildren,
				bubbles,
			});
		}
	});
	const [cookies, setCookie] = useCookies(['documentWidth']);
	const [documentWidth, setDocumentWidth] = useState(() => {
		return cookies.documentWidth ? parseInt(cookies.documentWidth, 10) : 320;
	});

	const resizeRef = useRef(hasResized);


	const [dragBubbles, setDragBubbles] = useState([]);

	const [autoResizeEffect, setAutoResizeEffect] = useState(() => {
		if (bubble.id) {
			return documentChildren.some((bubbleId) => {
				const bubble = bubbles.find((b) => b.id === bubbleId);
				return bubble?.isAutoResizeOnCreate;
			});
		}
		return false;
	});

	const handleResizeMouseDown = (e) => {
		const startX = e.clientX;
		const startWidth = documentWidth;

		const handleMouseMove = (e) => {
			const newWidth = e.clientX;
			if (newWidth < 320) {
				newWidth = 320;
			}
			setDocumentWidth(newWidth);
			setCookie('documentWidth', newWidth, { path: '/', maxAge: 604800 });
		};

		const handleMouseUp = () => {
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('mouseup', handleMouseUp);
		};

		document.addEventListener('mousemove', handleMouseMove);
		document.addEventListener('mouseup', handleMouseUp);
	};


	const currentFocus = useRef();
	currentFocus.bubble = focusedBubble;
	currentFocus.title = bubble.name;

	const cn =
		className || classnames(styles.document, styles[`${focusedBubble.viewMode}ViewMode`]);

	useEffect(() => {
		if (pdfContainerRef.current) {
			setContainerWidth(pdfContainerRef.current.offsetWidth);
		}
		}, [bubble.pdfContent]);
	useEffect(() => {
		if (focusedBubble.viewMode === 'document' || focusedBubble.viewMode == 'chat') {
			setDocumentWidth('100%');
		} else if (focusedBubble.viewMode === 'hybrid') {
			setDocumentWidth(cookies.documentWidth || 320);
		}
	}, [focusedBubble.viewMode]);

	// document should update when bubble id changes (main doc)
	useEffect(() => {
		if (bubble.id && type === 'full') {
			onBubbleChange();
		}
	}, [bubble.id]);


	useEffect(() => {
		refreshContent()
	}, [updateCount]);


	useEffect(() => {
		if (updateCount == undefined) {
			refreshContent()
		}
	}, [documentChildren]);


	// update text content when generating from API
	useEffect(() => {
		if (bubble.fromHtml?.length > 0) {
			let isBottomScroll = false;
			if (isChat) {
				let v = document.querySelector("[bubble-id=\"" + bubble.id + "\"]");
				isBottomScroll = v.scrollHeight - v.clientHeight <= v.scrollTop + 10;
			}
			if (isChat && editor.getBlock(documentChildren[0]).props.backgroundColor == "default") {
				removeDocumentBubbles({ parentBubbleId: bubble.id, ids: [documentChildren[0]] })
			}
			try {
				editor.tryParseMarkdownToBlocks(bubble.fromHtml).then((blocks) => {
					for (let i = 0; i < documentChildren.length && i < blocks.length; i++) {
						if (!isChat) blocks[i].id = documentChildren[i];
						updateBubble({
							id: documentChildren[i],
							properties: { fromHtml: '' },
						});
					}
					if (isChat) {
						let sizeCntr = 0;
						for (let i = 0; i < blocks.length; i++) {
							const color = ['ai', 'null'];
							const prevColor = blocks[i - 1] != undefined ? blocks[i - 1].props.backgroundColor.split('-') : null;
							if (color.length <= 1) continue;
							if (prevColor == null) {
								blocks[i].props.backgroundColor = color[0] + "-single";
								sizeCntr++;
								continue;
							}
							else if (color[1] == "null") {
								sizeCntr++;
								if (prevColor[0] == color[0]) {
									if (sizeCntr == 2) {
										blocks[i - 1].props.backgroundColor = prevColor[0] + "-start"
										blocks[i].props.backgroundColor = prevColor[0] + "-end"
									}
									else {
										blocks[i - 1].props.backgroundColor = prevColor[0] + "-middle"
										blocks[i].props.backgroundColor = prevColor[0] + "-end"
									}
								}
								else {
									blocks[i].props.backgroundColor = color[0] + "-single";
									sizeCntr = 1;
								}
							}
						}
						let existing = Array.from(documentChildren)
						const objs = document.querySelectorAll(`#document[bubble-id="${bubble.id}"] [data-node-type="blockOuter"][data-background-color="hu-single"]`);
						const obj = objs[objs.length - 1]
						existing.splice(0, obj != undefined ? existing.indexOf(obj.getAttribute('data-id')) + 1 : 0)
						for (let i = 0; i < existing.length && i < blocks.length; i++) {
							blocks[i].id = existing[i];
						}
						if (existing.length > 0) {
							editor.replaceBlocks(existing, blocks);

						}
						else {
							editor.insertBlocks(blocks, obj.getAttribute('data-id'), 'after');
						}

					}
					else {
						editor.replaceBlocks(documentChildren, blocks);
					}
					if (isChat && isBottomScroll) {
						document.querySelector("[bubble-id=\"" + bubble.id + "\"]").scrollTo({ top: 1e5, behavior: 'instant' });
					}
					updateBubble({
						id: bubble.id,
						properties: { fromHtml: '' },
					});

				})
			} catch (e) {
				console.error(e);
			}
		}
	}, [bubble.fromHtml]);


	useEffect(() => {
		resizeRef.current = hasResized;
	}, [hasResized]);

	useEffect(() => {
		resizeRef.current = hasResized;
	}, [hasResized]);

	const editInit = initialDoc?.length > 0 ? { initialContent: initialDoc, trailingBlock: false, schema: aiSchema, sideMenuDetection: 'editor' } : { initialContent: [{ id: documentChildren[0], type: 'paragraph', content: '' }], trailingBlock: false, schema: aiSchema, sideMenuDetection: 'editor' }
	const editor = useCreateBlockNote(editInit);

	useEffect(() => {
		if (editor.prosemirrorView == undefined) {
			syncBubbles()
		}
		try {
			editor.prosemirrorView.focus()
			editor.domElement.addEventListener("paste", () => {
				if (!resizeRef.current) {
					editor.prosemirrorView.dom.style.whiteSpace = "normal";
					checkIsOverflow()
					editor.prosemirrorView.dom.style.whiteSpace = "normal"
				}
				else if (editor.prosemirrorView != undefined) {
					editor.prosemirrorView.dom.style.whiteSpace = "normal";
				}
			})
			if (isChat) {
				document.querySelector("[bubble-id=\"" + bubble.id + "\"]").scrollTo({ top: 1e5, behavior: 'smooth' });
			}
		} catch (e) { }

	}, [editor.prosemirrorView])

	if (isChat) {
		useEffect(() => {
			document.getElementById('chat-canvas')?.addEventListener('dragend', (e) => {
				putFullChatOnCanvas(e)
			});
		}, [])
	}

	const putFullChatOnCanvas = (e) => {
		if (document.querySelectorAll("#canvas:hover").length == 0) return;
		const id = uuidv4()
		const bounds = document.getElementById("canvas").getBoundingClientRect()
		addCanvasBubble({
			id: id,
			location: {
				x: e.clientX - bounds.left,
				y: e.clientY - bounds.top
			},
			properties: {
				name: currentFocus.title
			}
		});
		updateCanvasBubbleProperties({
			id: id,
			canvasParentId: currentFocus.bubble.id,
			canvasIndex: currentFocus.bubble.canvasChildren.length ?? 0,
			canvasProperties: {
				dimensions: { width: 400, height: 200 },
			},
		});
		editor.forEachBlock((b) => {
			let draggedBlock = { ...b };
			draggedBlock.id = uuidv4()
			addDocumentBubble({
				id: draggedBlock.id,
				parentBubbleId: id,
				properties: {
					name: JSON.stringify(draggedBlock)
				}
			})
		})
	}


	useEffect(() => {
		if (editor.prosemirrorView?.hasFocus() && canvasFocus != undefined) {
			canvasFocus(bubble.id)
		}

	}, [editor.prosemirrorView?.hasFocus()])

	useEffect(() => {
		if (autoResizeEffect && editor.prosemirrorView != undefined) {

			checkIsOverflow()
			editor.prosemirrorView.dom.style.whiteSpace = "normal"
			updateDocumentBubble({
				id: bubble.id,
				canvasParentId,
				parentCanvasIndex,
				properties: { isAutoResizeOnCreate: false },
			});

			setAutoResizeEffect(false)
		}


	}, [autoResizeEffect, editor.prosemirrorView, updateCount])

	const onBubbleChange = () => {
		const newDoc = buildDocument({
			parentId: bubble.id,
			documentChildren,
			bubbles,
		});



		if (editor.document.length > 0) {
			const allBlocks = editor.document.reduce((r, b) => { r.push(b.id); return r; }, []);
			if (newDoc.length > 0) {
				editor.replaceBlocks(allBlocks, newDoc);
			}
			else {
				editor.removeBlocks(allBlocks)
			}
		}

	}
	const handleResize = (width, height) => {
		var newWidth = width
		var newHeight = height
		if (width > MaxAutoWidth) {
			newWidth = MaxAutoWidth
		}
		if (height > MaxAutoHeight) {
			newHeight = MaxAutoHeight
		}

		if (dimensions.width == MaxAutoWidth) {
			editor.prosemirrorView.dom.style.whiteSpace = "normal"
		}

		sizeExpander(_, { size: { width: newWidth, height: newHeight } })

	}


	const checkIsOverflow = () => {
		var newHeight = dimensions.height
		if (dimensions.height - 70 < editor.prosemirrorView.dom.scrollHeight) {
			newHeight = editor.prosemirrorView.dom.scrollHeight + 70
		}

		editor.prosemirrorView.dom.style.whiteSpace = "nowrap"

		var newWidth = dimensions.width

		if (dimensions.width - 14 <= editor.prosemirrorView.dom.scrollWidth) {
			newWidth = editor.prosemirrorView.dom.scrollWidth + 14
		}

		console.log(newWidth, editor.prosemirrorView.dom.scrollWidth)

		handleResize(newWidth, newHeight)
	}


	const refreshContent = () => {
		try {
			const newDoc = buildDocument({
				parentId: bubble.id,
				documentChildren,
				bubbles,
			});
			const textCursorPosition = editor.prosemirrorView ? editor.prosemirrorView.state.selection.anchor : null;
			if (editor.document.length > 0) {
				const allBlocks = editor.document.reduce((r, b) => { r.push(b.id); return r; }, []);
				if (newDoc.length > 0) {
					editor.replaceBlocks(allBlocks, newDoc);
				}
				else {
					allBlocks.pop()
					editor.removeBlocks(allBlocks)
				}
			}
			if (editor.prosemirrorView && textCursorPosition != null && !isChat && editor.prosemirrorView.hasFocus()) {
				const n = editor.prosemirrorView.state.apply(editor.prosemirrorView.state.tr.setSelection(TextSelection.create(editor.prosemirrorView.state.doc, textCursorPosition)))
				editor.prosemirrorView.state = n
				editor.prosemirrorView.focus()
			}
		} catch (e) {
		}
	}

	const onDocumentChange = () => {

		const prevContent = documentChildren;
		const existingNodes = [];
		//we never add the last bubble, as it is always empty
		for (let i = 0; i < editor.document.length; i++) {
			const doc = editor.document[i];
			existingNodes.push(doc.id)
			if (prevContent.indexOf(doc.id) >= 0) {
				const prevDoc = bubbles.find((b) => b.id === doc.id);

				const formattedName = JSON.stringify({ id: doc.id, type: doc.type, content: doc.content, props: doc.props, children: doc.children })
				if (prevDoc?.name != formattedName) {
					updateDocumentBubble({
						id: doc.id,
						canvasParentId,
						parentCanvasIndex,
						properties: { name: formattedName },
					});
				}
			}
			else {
				addDocumentBubble({
					id: doc.id,
					parentBubbleId: bubble.id,
					previousSibling: i > 0 ? editor.document[i - 1].id : "",
					properties: { name: JSON.stringify({ id: doc.id, type: doc.type, content: doc.content, props: doc.props, children: doc.children }) },
					canvasParentId,
					parentCanvasIndex,
				});
			}
		}

		if(editor.getTextCursorPosition().block?.content[0]?.text == "[] " && editor.getTextCursorPosition().block?.type == "paragraph"){
				const doc = editor.getTextCursorPosition().block;
			  	const formattedName = JSON.stringify({ id: doc.id, type: 'checkListItem', content: [{type:'text', text:'', styles:{}}], props: {checked: false}, children: doc.children })
					updateDocumentBubble({
						id: doc.id,
						canvasParentId,
						parentCanvasIndex,
						properties: { name: formattedName },
					});
				editor.replaceBlocks([doc.id], [JSON.parse(formattedName)]);
		}

		for (let i = 0; i < editor.document.length - 1; i++) {
			const oldIndex = prevContent.indexOf(editor.document[i].id);
			if (oldIndex != -1 && oldIndex != i) {
				const doc = editor.document[i];
				moveDocumentBubble({
					id: doc.id,
					newIndex: i,
					oldIndex,
					canvasParentId,
					parentCanvasIndex,
					parentBubbleId: bubble.id,
				});
			}
		}
		if (type === "inline" && dimensions != undefined && editor.prosemirrorView?.hasFocus() && !hasResized) {
			checkIsOverflow()

		}
		else if (editor.prosemirrorView != undefined) {
			editor.prosemirrorView.dom.style.whiteSpace = "normal";
		}


		const ghostNodes = documentChildren.filter(
			(id) => !existingNodes.includes(id),
		);

		if (ghostNodes?.length) {
			removeDocumentBubbles({
				parentBubbleId,
				ids: ghostNodes,
			});
		}
	};

	let initialBlock = undefined;

	const dragStart = () => {
		initialBlock = editor.prosemirrorView.dom.getElementsByClassName("ProseMirror-selectednode")[0];
	}

	const onDrag = async (e) => {
		const baseBlock = editor.prosemirrorView.dom.getElementsByClassName("ProseMirror-selectednode")[0];
		if (baseBlock == undefined) {
			if (initialBlock != undefined) {
				baseBlock = initialBlock;
			}
			else {
				return;
			}
		}

		let block = undefined;
		let listPar = undefined;
		if (baseBlock.querySelector(".bn-block-content").getAttribute("data-content-type") == "bulletListItem") {
			const recListSearch = (obj, sID) => {
				let res = undefined;
				if (typeof obj === 'object' && obj !== null) {
					if (obj['id'] == sID) {
						res = obj;
						obj = {};
					}
					if (res == undefined)
						for (let c of obj.children) {
							res = recListSearch(c, sID)[0]
							if (res != undefined) {
								obj.children.splice(obj.children.indexOf(c), 1)
								break;
							}
						}
				}
				return [res, obj];
			}
			for (let b of documentChildren) {
				let bubb = bubbles.find((f) => { return f.id == b })
				if (bubb && bubb?.name?.length > 0) {
					let toTest = JSON.parse(bubb.name);
					if (toTest.type != "bulletListItem") {
						continue;
					}
					const res = recListSearch(toTest, baseBlock.getAttribute("data-id"))
					block = res[0]
					if (block != undefined) {
						//console.log(res)
						//bubb.name = JSON.stringify(res[1]);
						listPar = toTest.id;
						break;
					}
				}
			}

		}
		else {
			block = JSON.parse(bubbles.find((b) => { return b.id == baseBlock.getAttribute("data-id") }).name);
		}


		//block[0].id = baseBlock.getAttribute("data-id");
		const canvasID = uuidv4()



		if (document.querySelectorAll("#document:hover").length > 0) {
		}
		else {
			const bounds = document.getElementById("canvas").getBoundingClientRect()
			addCanvasBubble({
				id: canvasID,
				location: {
					x: e.clientX - bounds.left,
					y: e.clientY - bounds.top
				}
			})
			addDocumentBubble({
				id: block.id,
				parentBubbleId: canvasID,
				properties: {
					name: JSON.stringify(block),
				}
			})
			if (!isChat) editor.removeBlocks([baseBlock.getAttribute("data-id")]);
			initialBlock = undefined
		}
	}

	const onChatDrag = (e, blockId) => {
		let currBlock = [];
		let isSelected = false;
		let isDone = false;
		const draggedBlock = { ...editor.getBlock(blockId) };
		if (document.querySelectorAll("#canvas:hover").length == 0) return;
		editor.forEachBlock((b) => {
			if (isDone) return;

			switch (b.props.backgroundColor) {
				case "ai-start":
				case "ai-middle":
					if (draggedBlock.id == b.id) {
						isSelected = true;
					}
					currBlock.push(b);
					break;
				case "ai-end":
					if (draggedBlock.id == b.id) {
						isSelected = true;
					}
					currBlock.push(b);
					if (isSelected) {
						const id = uuidv4()
						const bounds = document.getElementById("canvas").getBoundingClientRect()
						addCanvasBubble({
							id: id,
							location: {
								x: e.clientX - bounds.left,
								y: e.clientY - bounds.top
							}
						})
						currBlock.map((b) => {
							let bl = { ...b }
							bl.id = uuidv4()
							bl.props.backgroundColor = ""
							addDocumentBubble({
								id: bl.id,
								parentBubbleId: id,
								properties: {
									name: JSON.stringify(bl)
								}
							})
						})

						isDone = true;
					}
					else {
						currBlock = []
					}
					break;
				case "ai-single":
				case "hu-single":
					if (draggedBlock.id == b.id) {
						isDone = true;
						const id = uuidv4()
						const bounds = document.getElementById("canvas").getBoundingClientRect()
						addCanvasBubble({
							id: id,
							location: {
								x: e.clientX - bounds.left,
								y: e.clientY - bounds.top
							}
						})
						let bl = { ...draggedBlock }
						bl.id = uuidv4()
						bl.props.backgroundColor = ""
						addDocumentBubble({
							id: bl.id,
							parentBubbleId: id,
							properties: {
								name: JSON.stringify(bl)
							}
						})
					}
					break;
			}
		})
	};

	useLayoutEffect(() => {
		if (isChat) {
			let blocks = [];
			let current = {}
			setTimeout(() => {
				documentChildren.forEach((m) => {
					switch (document.querySelector('[bubble-id="' + bubble.id + '"] [data-node-type="blockOuter"][data-id="' + m + '"]')?.getAttribute("data-background-color")) {
						case "ai-start":
							current.contRect = document.querySelector('[bubble-id="' + bubble.id + '"]>.textContent')?.getBoundingClientRect()
							current.type = document.querySelector('[bubble-id="' + bubble.id + '"] [data-node-type="blockOuter"][data-id="' + m + '"]')?.getAttribute("data-background-color");
							current.dims = document.querySelector('[bubble-id="' + bubble.id + '"] [data-node-type="blockOuter"][data-id="' + m + '"]')?.getBoundingClientRect()
							current.id = m;
							break;
						case "ai-middle":
							current.dims.height += document.querySelector('[bubble-id="' + bubble.id + '"] [data-node-type="blockOuter"][data-id="' + m + '"]')?.getBoundingClientRect().height;
							break;
						case "ai-end":
							current.dims.height += document.querySelector('[bubble-id="' + bubble.id + '"] [data-node-type="blockOuter"][data-id="' + m + '"]')?.getBoundingClientRect().height;
							blocks.push(current)
							current = {}
							break;
						case "ai-single":
						case "hu-single":
							current.contRect = document.querySelector('[bubble-id="' + bubble.id + '"]>div')?.getBoundingClientRect()
							current.type = document.querySelector('[bubble-id="' + bubble.id + '"] [data-node-type="blockOuter"][data-id="' + m + '"]')?.getAttribute("data-background-color");
							current.dims = document.querySelector('[bubble-id="' + bubble.id + '"] [data-node-type="blockOuter"][data-id="' + m + '"]')?.getBoundingClientRect()
							current.id = m;
							blocks.push(current)
							current = {}
							break;
					}
				})
				setDragBubbles(blocks)
			}, 100)
		}
	})

	const captureCopyPaste = (e) => {
		e.stopPropagation();
	};

	const timeoutRef = useRef(null);

	const changeViewMode = () => {
		if (focusedBubble.viewMode!='hybrid') {
			setViewMode('hybrid');
		}
	};
  
	const handleDragLeave = (event) => {
	  if (!event.relatedTarget || !event.currentTarget.contains(event.relatedTarget)) {
		timeoutRef.current = setTimeout(changeViewMode, 500);
	  }
	};
  
	const handleDragEnter = () => {
	  if (timeoutRef.current) {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = null;
	  }
	};

	const dynamicStyles = {
		minWidth: 'inherit',
		maxWidth: focusedBubble.viewMode === 'document' ? '100%' : documentWidth,
	};

	return (
		<div
		  className={cn}
		  id="document"
		  onKeyDown={captureCopyPaste}
		  bubble-id={bubble.id}
		  style={type === 'full' ? dynamicStyles : {}}
		  onDragLeave={handleDragLeave}
		  onDragEnter={handleDragEnter}
		>
		  {type === 'full' && <ViewModeControls />}
		  <div className={styles.contentContainer}>
			{bubble.imageContent && <img src={bubble.imageContent} />}
			{bubble.pdfContent && (
			  <div className={styles.pdfViewerContainer}>
				{bubble.pdfContent === "loading" ? (
				  <ImSpinner4 className={styles.spinner} />
				) : (
				  <PdfDocument
					file={`${BASE_URL}/proxy/pdf?fileUrl=${bubble.pdfContent.split(';')[1] ?? bubble.pdfContent}`}
					onLoadSuccess={({ numPages }) => setNumPages(numPages)}
					loading={<ImSpinner4 className={styles.spinner} />}
				  >
					{numPages && Array.from(new Array(numPages), (el, index) => (
					  <PdfPage
						key={`page_${index + 1}`}
						pageNumber={index + 1}
						width={documentWidth - 32}
					  />
					))}
				  </PdfDocument>
				)}
			  </div>
			)}
			<div className={styles.editorContainer} style={type === 'full' ? {minHeight:'50vh'} : {}}>
			  <div className={`textContent ${styles.textContent}`}>
				{initialDoc || (isChat && documentChildren?.length > 1) ? (
				  <BlockNoteView editor={editor} onChange={onDocumentChange} formattingToolbar={false} editable={!isChat} theme={"light"} onDragEnd={onDrag} onDragStart={dragStart} slashMenu={false} sideMenu={true} className={styles.blocknote}>
					<SuggestionMenuController
					  triggerCharacter={"/"}
					  getItems={async (query) =>
						filterSuggestionItems(
						  [...getDefaultReactSlashMenuItems(editor).filter((i)=>i.title != "Check List"), insertAIcontent(editor), insertCheckListItem(editor, query.toLowerCase())],
						  query
						)
					  }
					/>
					<SuggestionMenuController
					  triggerCharacter={"@"}
					  getItems={async (query) =>
						filterSuggestionItems(
						  getBubbleLinkItem(editor),
						  query
						)
					  }
					/>
					<FormattingToolbarController
					  formattingToolbar={() => (
						<FormattingToolbar>
						  <BlockTypeSelect key={"blockTypeSelect"} />
						  <CreateLinkButton key={"createLinkButton"} />
						  <BubbleLinkButton key={"bubbLink"} />
						  <FileCaptionButton key={"fileCaptionButton"} />
						  <FileReplaceButton key={"replaceFileButton"} />
						  <BasicTextStyleButton
							basicTextStyle={"bold"}
							key={"boldStyleButton"}
						  />
						  <BasicTextStyleButton
							basicTextStyle={"italic"}
							key={"italicStyleButton"}
						  />
						  <BasicTextStyleButton
							basicTextStyle={"underline"}
							key={"underlineStyleButton"}
						  />
						  <BasicTextStyleButton
							basicTextStyle={"strike"}
							key={"strikeStyleButton"}
						  />
						  <TextAlignButton
							textAlignment={"left"}
							key={"textAlignLeftButton"}
						  />
						  <TextAlignButton
							textAlignment={"center"}
							key={"textAlignCenterButton"}
						  />
						  <TextAlignButton
							textAlignment={"right"}
							key={"textAlignRightButton"}
						  />
						  <ColorStyleButton key={"colorStyleButton"} />
						  <NestBlockButton key={"nestBlockButton"} />
						  <UnnestBlockButton key={"unnestBlockButton"} />
						</FormattingToolbar>
					  )}
					/>
				  </BlockNoteView>
				) : null}
				{
				  isChat ? dragBubbles.map((m) => {
					if (m.dims != undefined && m.contRect != undefined) {
					  return (<div style={{ overflowAnchor: 'none' }}>
						<div
						  className={classnames(['sideHandle', styles.sideHandle])}
						  onDragEnd={(e) => { onChatDrag(e, m.id) }}
						  style={{
							zIndex: 2000,
							position: 'absolute',
							top: m.dims.top - m.contRect.top + 18,
							left: m.dims.left - m.contRect.left - 28,
							transform: m.type == 'hu-single' ? 'translateY(-.45rem)' : 'none'
						  }}
						  draggable>
						  ⠿
						</div>
					  </div>)
					}
				  }) : null
				}
				{isChat && <div className={`bottomAnchor ${styles.bottomAnchor}`}></div>}
			  </div>
			</div>
		  </div>
		  {type === 'full' ? (
			<div
			  className={styles.resizeHandle}
			  onMouseDown={handleResizeMouseDown}
			/>
		  ) : null}
		</div>
	  );
};

export const InlineDocument = ({
	bubble,
	parentCanvasIndex,
	className,
	updateCount,
	canvasParentId,
	dimensions,
	sizeExpander,
	MaxAutoWidth,
	MaxAutoHeight,
	focusedCanvasBubbleId,
	hasResized,
	canvasFocus,
}) => (
	<Document
		dimensions={dimensions}
		updateCount={updateCount}
		bubble={bubble}
		parentCanvasIndex={parentCanvasIndex}
		canvasParentId={canvasParentId}
		className={className}
		type="inline"
		sizeExpander={sizeExpander}
		MaxAutoWidth={MaxAutoWidth}
		MaxAutoHeight={MaxAutoHeight}
		focusedCanvasBubbleId={focusedCanvasBubbleId}
		hasResized={hasResized}
		canvasFocus={canvasFocus}

	/>
);


export const ChatDocument = ({
	bubble,
	className,
}) => (
	<Document
		bubble={bubble}
		className={className}
		isChat={true}
		type='chat'
	/>
);

export default Document;
