import React from 'react';
import Scratchpad from '../components/main-view/scratchpad';
import { Mheader } from '../components/header/Mheader';
import styles from './MApp.module.sass';

const Mobile = () => {
  return (
    <div className={styles.mobileContainer}>
      <div className={styles.headerWrapper}>
        <Mheader />
      </div>
      <div className={styles.scratchpadWrapper}>
        <Scratchpad />
      </div>
    </div>
  );
};

export default Mobile;