import classnames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import { ArrowUp, Save, Check, SquarePen, SquareChevronUp } from 'lucide-react';
import { useCookies } from 'react-cookie';
import { ImSpinner4 } from "react-icons/im";
import styles from './chat-canvas.module.sass';
import { ChatDocument } from './document/document';
import { v4 as uuidv4 } from 'uuid';
import { getBubbleName } from './document/document-func';
import Button from '../common/button';
import Dropdown from '../common/dropdown';

import {
	useBubblesActions,
	useBubblesState,
} from '../../store/hooks/use-bubbles';
import { forEach, head } from 'lodash';

const ChatCanvas = () => {
	const [cookies, setCookie, removeCookie] = useCookies(['conversationBubble']);
	const [scope, setScope] = useState('universal');
	const [activeChat, setActiveChat] = useState(false);
	const [showFullPromptModule, setShowFullPromptModule] = useState(false);
	const [conversationBubble, setConversationBubble] = useState(() => {
		return cookies.conversationBubble ? cookies.conversationBubble : undefined;
	});
	const [conversationTitle, setConversationTitle] = useState('');
	const [hasToScroll, setHasToScroll] = useState(true);
	const { bubbles, focusedBubble ,focusedBubbleId, selectedBubbles, aiLoading } = useBubblesState();
	const { addBubble, addDocumentBubble, addCanvasBubble, handleTextGeneration, updateCanvasBubbleProperties, updateBubble } = useBubblesActions();
	const [isSaved, setIsSaved] = useState(false);
	const canvasRef = useRef();
	let input = useRef();
	let isOpen = useRef();
	isOpen.state = showFullPromptModule;


	
	if (!conversationBubble) {
		const id = uuidv4();
		addBubble({ id, viewMode: 'chat' });
		setConversationBubble(id);
		setCookie('conversationBubble', id, { path: '/', maxAge: 60 * 60 * 24 });
	}

	let bubble = bubbles.find((b) => b.id === conversationBubble);
	const canvasbubble = bubbles.find((b) => b.id === focusedBubbleId);

	if (!bubble) {
		const id = uuidv4();
		addBubble({ id, viewMode: 'chat' });
		setConversationBubble(id);
		setCookie('conversationBubble', id, { path: '/', maxAge: 60 * 60 * 24 });
		bubble = bubbles.find((b) => b.id === conversationBubble);
	}

	useEffect(()=>{
			setActiveChat(bubble.documentChildren.length > 1)
	}, [conversationBubble])

	const getSelectedContent = () => {
		const focusedBubble = bubbles.find((b) => b.id === focusedBubbleId);
		if (!focusedBubble) {
			console.warn("Focused bubble not found");
			return null;
		}

		const getContentAndChildren = (currentBubble) => {
			if (!currentBubble) {
				console.warn("Invalid bubble passed to getContentAndChildren");
				return null;
			}

			const children = currentBubble.expanded
				? currentBubble.canvasChildren
					?.map(({ id }) => bubbles.find((b) => b.id === id))
					.filter(Boolean)
					.map(getContentAndChildren)
				: null;

			const docBubbs = currentBubble.documentChildren
				?.map((m) => {
					const childBubble = bubbles.find((b) => b.id === m);
					return childBubble ? getBubbleName(childBubble) : "Unnamed Bubble";
				})
				.filter(Boolean);

			if (currentBubble.name) {
				docBubbs.push(getBubbleName(currentBubble));
			}

			return {
				text: docBubbs,
				children,
			};
		};

		const parents = selectedBubbles.map((index) => {
			const child = focusedBubble.canvasChildren[index];
			if (!child) {
				console.warn(`Invalid index ${index} in selectedBubbles`);
				return null;
			}
			return bubbles.find((b) => b.id === child.id);
		}).filter(Boolean);

		if (parents?.length) {
			return parents.map(getContentAndChildren);
		}

		return null;
	};

	let selectedContent = getSelectedContent();

	const chatMemory=(content = {})=>{

			content.history =  [];
			for (let i = 0; i < bubble.documentChildren.length; i++) {
				const chatBubble=bubbles.find((b) => b.id === bubble.documentChildren[i])
				const chatBubbleNameParsed=JSON.parse(chatBubble.name)
				if (chatBubbleNameParsed.props.backgroundColor == "hu-single") {
				   
					const newEntry = {
						role: "user",
						content:getBubbleName(chatBubble) ,
					};
					content.history.push(newEntry);
				}
				else{
					const newEntry = {
						role: "assistant",
						content:getBubbleName(chatBubble) ,
					};
					content.history.push(newEntry);
				}
				
			}
			return content;
	}

	if(!selectedContent && scope == 'useSelectedForContext'){
		setScope('universal')
	}

	const sendMessage = async (e) => {
		e.stopPropagation();
		const userMessage = input.current.textContent.trim();
		if (!userMessage) return;
		setActiveChat(true);
		setShowFullPromptModule(true)
		input.current.textContent = "";
		setIsSaved(false);
		const id = uuidv4();

		const getCanvasContent = (currentBubble) => {
			if (!currentBubble) return null;

			const children = currentBubble.canvasChildren
				?.map(({ id }) => bubbles.find((b) => b.id === id))
				.filter(Boolean)
				.map(getCanvasContent);

			const docBubbs = currentBubble.documentChildren
				?.map((childId) => {
					const childBubble = bubbles.find((b) => b.id === childId);
					return childBubble ? getBubbleName(childBubble) : "Unnamed Bubble";
				})
				.filter(Boolean);

			if (currentBubble.name) {
				docBubbs.push(getBubbleName(currentBubble));
			}

			if(currentBubble.pdfSummary){
				docBubbs.push(currentBubble.pdfSummary)
			}

			console.log(docBubbs);

			return {
				text: docBubbs,
				children,
			};
		};

		if (scope === "currentView") {
			const canvasContent = getCanvasContent(canvasbubble);
			console.log(canvasContent)
			addDocumentBubble({
				id: id,
				parentBubbleId: conversationBubble,
				properties: {
					name: `{"id":"${id}","type":"paragraph","content":[{"type":"text","text":${JSON.stringify(userMessage)}}],"props":{"textColor":"default","backgroundColor":"hu-single","textAlignment":"left"},"children":[]}`,
				},
			});
			handleTextGeneration({
				promptText: userMessage,
				parentId: conversationBubble,
				content: chatMemory(canvasContent),
			});
		} else if (scope === "useSelectedForContext") {
			addDocumentBubble({
				id: id,
				parentBubbleId: conversationBubble,
				properties: {
					name: `{"id":"${id}","type":"paragraph","content":[{"type":"text","text":${JSON.stringify(userMessage)}}],"props":{"textColor":"default","backgroundColor":"hu-single","textAlignment":"left"},"children":[]}`,
				},
			});
			handleTextGeneration({
				promptText: userMessage,
				parentId: conversationBubble,
				content: chatMemory(selectedContent),
			});
		} else {
			addDocumentBubble({
				id: id,
				parentBubbleId: conversationBubble,
				properties: {
					name: `{"id":"${id}","type":"paragraph","content":[{"type":"text","text":${JSON.stringify(userMessage)}}],"props":{"textColor":"default","backgroundColor":"hu-single","textAlignment":"left"},"children":[]}`,
				},
			});
			
			handleTextGeneration({
				promptText: userMessage,
				parentId: conversationBubble,
				content: chatMemory()
			});

		
		}
		setTimeout(() => {
			document.querySelector("[bubble-id=\""+bubble.id+"\"]").scrollTo({top:1e5, behavior:'smooth'});
		}, 10);
	};

	useEffect(()=>{
		const delta = 6;
		let startX;
		let startY;
		
		document.getElementById('canvas').addEventListener('mousedown', function (event) {
		  startX = event.pageX;
		  startY = event.pageY;
		});
		
		document.getElementById('canvas').addEventListener('mouseup', function (event) {
		  const diffX = Math.abs(event.pageX - startX);
		  const diffY = Math.abs(event.pageY - startY);
		  if(isOpen.state){
			setShowFullPromptModule(!(diffX < delta && diffY < delta));
		}
		});
		

	}, [])

	useEffect(()=>{
		if(hasToScroll && showFullPromptModule){
			document.querySelector("[bubble-id=\""+bubble.id+"\"]").scrollTo({top:1e5, behavior:'smooth'});
			setHasToScroll(false);
		}
	}, [showFullPromptModule])
	
	useEffect(()=>{
		if(!aiLoading){
			input.current.focus();
		}
	}, [aiLoading])

	useEffect(() => {
		if (input.current) {
			input.current.focus();
		}
	}, [conversationBubble]);

	const resetConversationBubble = (e) => {
		e.stopPropagation();
		const newId = uuidv4();
		addBubble({ id: newId, viewMode: 'chat' });
		removeCookie('conversationBubble', { path: '/' });
		setConversationBubble(newId);
		setCookie('conversationBubble', newId, { path: '/', maxAge: 60 * 60 * 24 });
		setConversationTitle('');
		setActiveChat(false);
		input.current.focus();
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			sendMessage(e);
		}
	};

	useEffect(() => {
		if (conversationBubble && !conversationTitle) {
			const bubble = bubbles.find((b) => b.id === conversationBubble);
			if (bubble && bubble.name) {
				setConversationTitle(getBubbleName(bubble));
			}
		}
	}, [conversationBubble, bubbles]);

	const handleSave = (e) => {
		e.stopPropagation();
		const bubble = bubbles.find((b) => b.id === conversationBubble);
		if (bubble) {
			updateBubble({
				id: bubble.id,
				properties: {}, 
				forceUpdate: true, 
			});
			setIsSaved(true);
		}
	};

	const handleAddToCanvas = (e) => {
		e.stopPropagation();
		const bubble = bubbles.find((b) => b.id === conversationBubble);
		if (bubble) {
			const id = uuidv4();
			const bounds = document.getElementById("canvas").getBoundingClientRect();
			const x = 550;
			const y = 160; 
	
			addCanvasBubble({
				id: id,
				location: { x, y },
				properties: {
					name: bubble.name,
				},
			});

			bubble.documentChildren.forEach((childId) => {
				const childBubble = bubbles.find((b) => b.id === childId);
				if (childBubble) {
					const newChildId = uuidv4();
					addDocumentBubble({
						id: newChildId,
						parentBubbleId: id,
						properties: {
							name: childBubble.name,
						},
					});
				}
			});
			updateCanvasBubbleProperties({
				id: id,
				canvasParentId: focusedBubbleId,
				canvasIndex: focusedBubble.canvasChildren.length ?? 0,
				canvasProperties: {
					dimensions: { width: 400, height: 200},
				},
			});
		}
	};

	return (
		<div
			ref={canvasRef}
				className={classnames(
			styles.promptModule,
			"promptModule",
			{ [styles.activeChat]: activeChat },
			{ [styles.showFull]: showFullPromptModule }
		)}
		>
				<div
				className={styles.header}
				id='chat-canvas'
				onClick={() => setShowFullPromptModule(!showFullPromptModule)}
				draggable={true}
			>
				<div className={styles.titleSpinner}>
					{aiLoading &&                    
						<div>
							<ImSpinner4 className={styles.spinner}/>
						</div>
					}
					<div className={styles.headerTitle}>
						{conversationTitle || ''}
					</div>
				</div>
				{showFullPromptModule ? (
					<div className={styles.headerButtons}>
						<Button
							icon={<SquareChevronUp size={16} />}
							label="Add to canvas"
							size="small"
							onClick={handleAddToCanvas}
						/>
						<Button
							icon={isSaved ? <Check size={16} /> : <Save size={16} />}
							label={isSaved ? "Saved" : "Save"}
							size="small"
							onClick={handleSave}
							disabled={isSaved}
						/>
						<Button
							icon={<SquarePen size={16} />}
							label="New"
							size="small"
							onClick={resetConversationBubble}
						/>
					</div>
				) : (
					<div className={styles.openChatText}>
						Click to open the chat
					</div>
				)}
			</div>
		 
			<ChatDocument bubble={bubble} className={classnames([styles.document, bubble?.documentChildren?.length > 1 ? "" : styles.documentCollapse])} /> 

			<div className={styles.selectScope}>
				<div className={styles.scopes}>
					<div
						className={scope === 'universal' ? styles.selected : ''}
						onClick={(e) => {
							e.stopPropagation();
							setScope('universal');
						}}
					>
						ChatGPT
					</div>
					<div
						className={scope === 'currentView' ? styles.selected : ''}
						onClick={(e) => {
							e.stopPropagation();
							setScope('currentView');
						}}
					>
						Current workspace
					</div>
					<div className={styles.useSelected}  onClick={(e) => {
								e.stopPropagation();
							}}>
						<input
							type="checkbox"
							id="useSelected"
							disabled={!selectedContent} 
							onChange={(e) => {
								e.stopPropagation();
								if (e.target.checked && selectedContent) {
									setScope('useSelectedForContext'); 
								} else {
									setScope('universal'); 
								}
							}}
							checked={scope === 'useSelectedForContext' && selectedContent} 
							onClick={(e) => {
								e.stopPropagation();
							}}
						/>
						<label
							htmlFor="useSelected"
							disabled={!selectedContent} 
							onChange={(e) => {
								e.stopPropagation();
								if (e.target.checked && selectedContent) {
									setScope('useSelectedForContext'); 
								} else {
									setScope('universal'); 
								}
							}}
							checked={scope === 'useSelectedForContext'} 
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							Use selected for context
						</label>
					</div>
				</div>
				<div className={styles.promptPresets}>
					<Dropdown
						trigger={"Run prompt preset"}
						disabled={scope !== "currentView"}
						options={[
							{
								label: "Create a blog post outline",
								value: "blog-post-outline",
								content: "Write a blog text outline based on the content here. Optimize the outline for human readability, skimmability, and SEO. Fill in the main points after each headline.",
							},
							{
								label: "Create a social media post",
								value: "social-media-post",
								content: "Write a social media post based on the content here. Optimize the post for engagement, readability, and skimmability. Fill in the main points after each headline.",
							},
							{
								label: "Summarize",
								value: "summarize",
								content: "Summarize the content here. Find the main points and key takeaways and actions to take, if any.",
							},
							{
								label: "Translate to English",
								value: "translate-to-english",
								content: "Translate the content here to English. Make sure to keep the original meaning and intent of the content.",
							},
							{
								label: "Extract action items",
								value: "extract-action-items",
								content: "Extract the action items from the content here. Use markdown to format the action items.",
							},
							// {
							// 	label: "+ Create a new preset",
							// 	value: "createNewPreset",
							// },
						]}
						onSelect={(option) => {
							if (option.value === "createNewPreset") {
								// Handle creating a new preset (can be implemented later)
								console.log("Create new preset selected");
							} else if (option.content) {
								// Set the input content to the preset content
								input.current.textContent = option.content;
								// Focus the input
								input.current.focus();
								// Submit the message
								sendMessage({ stopPropagation: () => {} });
							}
						}}
					/>
				</div>
			</div>

			<div className={styles.inputContainer}>
				<div
					ref={input}
					className={classnames([styles.input, 'text-input'])}
					contentEditable={!aiLoading}
					data-placeholder={aiLoading ? "Generating..." : "Ask a question..."}
					onClick={(e) => {e.stopPropagation(); setShowFullPromptModule(true)}}
					onKeyDown={(e) => handleKeyDown(e)}
				></div>
				<Button
					icon={<ArrowUp size={16} />}
					onClick={(e) => sendMessage(e)}
					disabled={aiLoading}
				/>
			</div>
		</div>
	);
};

export default ChatCanvas;
