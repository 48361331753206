const initialHistoryState = {
  stack: [],
  index: -1,
};

export const historyReducer = (state = initialHistoryState, action) => {
  const safeState = {
    stack: Array.isArray(state?.stack) ? state.stack : initialHistoryState.stack,
    index: typeof state?.index === 'number' ? state.index : initialHistoryState.index,
  };

  if (action.type === 'RESET_HISTORY') {
    return {
      stack: [action.payload.location],
      index: 0,
    };
  }
  if (action.type === 'CLEAR_HISTORY') {
    return initialHistoryState;
  }
  if (action.type === 'HISTORY_GO_BACK') {
    if (safeState.index > 0) {
      return { ...safeState, index: safeState.index - 1 };
    }
    return safeState;
  }
  if (action.type === 'HISTORY_GO_FORWARD') {
    if (safeState.index < safeState.stack.length - 1) {
      return { ...safeState, index: safeState.index + 1 };
    }
    return safeState;
  }
  if (action.type === '@@router/LOCATION_CHANGE') {
    const { payload } = action;
    const { location, action: historyAction } = payload;

    const isGoogleOAuth = location.pathname.includes('accounts.google.com');
    if (isGoogleOAuth) {
      return safeState;
    }

    if (historyAction === 'PUSH') {
      const newStack =
        safeState.index < safeState.stack.length - 1
          ? safeState.stack.slice(0, safeState.index + 1)
          : safeState.stack;
      const lastLocation = newStack[newStack.length - 1];
      const isDuplicate =
        lastLocation &&
        lastLocation.pathname === location.pathname &&
        lastLocation.search === location.search &&
        lastLocation.hash === location.hash;
      if (isDuplicate) {
        return safeState;
      }
      return {
        stack: [...newStack, location],
        index: newStack.length,
      };
    } else if (historyAction === 'REPLACE') {
      if (safeState.index >= 0) {
        const newStack = [...safeState.stack];
        newStack[safeState.index] = location;
        return { ...safeState, stack: newStack };
      }
      return safeState;
    } else if (historyAction === 'POP') {
      const newIndex = safeState.stack.findIndex((loc) => loc.key === location.key);
      if (newIndex !== -1) {
        return { ...safeState, index: newIndex };
      }
      return safeState;
    }
  }
  return safeState;
};