import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";

import {
	useBlockNoteEditor,
	useComponentsContext,
	useEditorContentOrSelectionChange,
} from "@blocknote/react";

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createReactInlineContentSpec, LinkToolbar } from "@blocknote/react";
import * as mantine from "@blocknote/mantine"

import styles from "./block-link.module.sass"

import { Search, Heading, LinkIcon, ChevronDown, Check, PictureInPicture } from "lucide-react";

import { useBubblesActions } from '../../../store/hooks/use-bubbles';

import { searchBubbles } from "../../../api";

import { RiExternalLinkFill, RiLinksLine, RiSearchLine, RiText, RiEyeLine  } from "react-icons/ri";

import { v4 as uuidv4 } from 'uuid';



// The Mention inline content.
export const bubbleLink = createReactInlineContentSpec(
	{
		type: "bubbleLink",
		propSchema: {
			bubbleId: {
				default: "",
			},
		},
		content: "styled",
	},
	{
		render: (props) => {
			const countdownRef = useRef();
			const [tmpId, setTmpId] = useState((Math.random() + 1).toString(36).substring(7));
			const [title, setTitle] = useState(props.inlineContent.content.map(item => item.text).join(''));
			const { loadBubbles, addCanvasBubble } = useBubblesActions();
			const [bubbId, setBubbId] = useState(props.inlineContent.props.bubbleId);
			const [showMenu, setShowMenu] = useState(false);
			const [showEditor, setShowEditor] = useState(false);

			const linkDragEnd = (e) => {
				console.log({ ...e })
			};

			const saveLink = () => {
				if (bubbId.length == 0) {
					return;
				}
				props.updateInlineContent({ ...props.inlineContent, props: { ...props.inlineContent.props, bubbleId: bubbId } });
			}

			useEffect(() => {
				saveLink()
			}, [bubbId])

			const onTextFocus = () => {
				clearTimeout(countdownRef.current)
				setShowMenu(true);
			}

			const onTextFocusEnd = () => {
				if (window.getSelection()?.anchorNode?.parentNode?.parentElement?.id == tmpId || window.getSelection()?.anchorNode?.className?.indexOf("mantine-TextInput-wrapper") > -1) return;
				countdownRef.current = setTimeout(() => {
					setShowMenu(false);
					setShowEditor(false);
				}, 250);
			}

			const onTitleEnter = (e) => {
				if (e.key === 'Enter') {
					setTitle(e.target.value)
					props.updateInlineContent({ ...props.inlineContent, content: e.target.value });
					setShowMenu(false);
					setShowEditor(false);
				}
			}

			const onDragStart = (e) => {
				console.log("start")
				e.preventDefault();
				e.stopPropagation();
				e.persist();
				onTextFocus();
				e.dataTransfer.setData("bubbleId", props.inlineContent.props.bubbleId);
				const img = new Image();
				img.src = "bubble-dnd-placeholder.png";
				img.style.zIndex = 2000;
				//e.dataTransfer.setDragImage(img, 0, 0);
			}

			const onDrag = () => {
				console.log("alam");
				onTextFocus();
			};

			const onDragOver = (e) => {
				console.log("overrr");
				e.preventDefault();
				e.stopPropagation();
			};

			const onDragEndDiv = (e) => {
				console.log("drag end");
			};

			const openInNewTab = () => {
				window.open(window.location.origin + "/" + props.inlineContent.props.bubbleId, '_blank').focus();
			}

			const openMirrored = ()=>{
				if(!bubbId) return;
				addCanvasBubble({
					id: bubbId
				})
			}

			useEffect(() => {
				addEventListener("keyup", () => {
					if (window.getSelection()?.anchorNode?.parentNode?.parentElement?.id == tmpId) {
						onTextFocus()
					}
					else {
						onTextFocusEnd()
					}
				})
			}, [])


			return (

				<span
					onClick={() => { loadBubbles({ newFocusedBubbleId: props.inlineContent.props.bubbleId }) }}
					ref={props.contentRef}
					onMouseEnter={onTextFocus}
					onMouseLeave={onTextFocusEnd}
					className={styles.content}
					onDragEnd={linkDragEnd}
					id={tmpId}
				>
					<div contentEditable={false} onDragOver={onDragOver} tabIndex={-1} className={styles.toolbarContainer + " " + (showMenu ? styles.visible : "")} onMouseEnter={onTextFocus} onMouseLeave={onTextFocusEnd} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
						<LinkToolbar>
							<mantine.components.Generic.Popover.Root opened={showEditor}>
								<mantine.components.Generic.Popover.Trigger>
									<mantine.components.LinkToolbar.Button mainTooltip="Edit" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowEditor(v => !v); setTitle(props.inlineContent.content.map(item => item.text).join('')); }}>Edit bubble link</mantine.components.LinkToolbar.Button>
								</mantine.components.Generic.Popover.Trigger>
								<mantine.components.Generic.Popover.Content variant={"form-popover"} className={"bn-popover-content bn-form-popover"}>
									<mantine.components.Generic.Form.Root>
										<mantine.components.Generic.Form.TextInput
											name="title"
											icon={<RiText />}
											value={title}
											className="bn-text-input"
											placeholder="Edit title"
											onKeyDown={onTitleEnter}
											onChange={(v) => { setTitle(v.target.value) }}
											autoFocus={true}
										/>
										<SearchBar selectedId={bubbId} onBubbleSelect={(id) => { setBubbId(id); }}></SearchBar>
									</mantine.components.Generic.Form.Root>
								</mantine.components.Generic.Popover.Content>
							</mantine.components.Generic.Popover.Root>
							<mantine.components.LinkToolbar.Button mainTooltip="Open in new tab" onClick={openInNewTab} icon={<RiExternalLinkFill />}></mantine.components.LinkToolbar.Button>
							{/*<div draggable={true} onDragStart={onDragStart} onDrag={onDrag} onDragOver={onDragOver} onDragEnd={onDragEndDiv} style={{ height: "100%", width: "2rem", display: "flex", justifyContent: 'center', alignItems: 'center', cursor: 'grab' }}>⠿</div>*/}
							<mantine.components.LinkToolbar.Button mainTooltip="Add to canvas" icon={<PictureInPicture size={16} />} onClick={openMirrored}></mantine.components.LinkToolbar.Button>
						</LinkToolbar>
					</div>
				</span>
			)
		},
	}
);

export const getBubbleLinkItem = (editor) => {

	return [{
		title: "Add bubble link",
		icon: <LinkIcon />,
		onItemClick: () => {
			editor.insertInlineContent([
				{
					type: "bubbleLink",
					content: 'test link'
				},
				" "
			]);
		}
	}]
};

export const BubbleLinkButton = () => {
	const editor = useBlockNoteEditor();
	const [title, setTitle] = useState(editor.getSelectedText());
	const [bubbId, setBubbId] = useState("");
	const [showEditor, setShowEditor] = useState(false);
	const Components = useComponentsContext();
	const onBubbSelect = (id) => {
		editor.insertInlineContent([
			{
				type: "bubbleLink",
				content: title,
				props: {
					bubbleId: id
				},
			},
		])
		setBubbId(id);
	}

	editor.prosemirrorView.dom.addEventListener("keydown", (e)=>{
		if(e.key == "K" && e.ctrlKey && e.altKey && e.shiftKey){
			e.preventDefault()
			setShowEditor(true)
		}
	})

	return (
		<Components.Generic.Popover.Root opened={showEditor}>
			<Components.Generic.Popover.Trigger>
				<Components.LinkToolbar.Button mainTooltip="Create bubble link" secondaryTooltip="Ctrl+Alt+Shift+K" icon={<img style={{ width: "1rem" }} className="bubbleLinkIcon" src="https://bubb.ls/bubbles-logomark-black.svg" />} onClick={()=>{setShowEditor(c=>!c)}}></Components.LinkToolbar.Button>
			</Components.Generic.Popover.Trigger>
			<Components.Generic.Popover.Content variant={"form-popover"} className={"bn-popover-content bn-form-popover"}>
				<Components.Generic.Form.Root>
					<Components.Generic.Form.TextInput
						name="title"
						icon={<RiText />}
						value={title}
						className="bn-text-input"
						placeholder="Edit title"
						onChange={(v) => { setTitle(v.target.value) }}
					/>
					<SearchBar selectedId={bubbId} onBubbleSelect={onBubbSelect} focus={true}></SearchBar>
				</Components.Generic.Form.Root>
			</Components.Generic.Popover.Content>
		</Components.Generic.Popover.Root>
	);
}


const parseName = (name) => {
	try {
		return JSON.parse(name).content.map((content) => content.text);
	} catch {
		return [name];
	}
};

const SearchResult = ({ result, setSearchResults, setSearchQuery, onClick }) => {
	const parsed = parseName(result.name);

	const loadSearchResult = async () => {
		setSearchResults([]);
		setSearchQuery(parseName(result.name)[0]);
		onClick(result.id);
	};

	return (
		<div className={styles.searchResult} onClick={loadSearchResult}>
			{parsed.map((content, index) => (
				<p key={`${content}-${index}`}>{content}</p>
			))}
		</div>
	);
};

const SearchBar = ({ selectedId, reload = false, onBubbleSelect, focus = false }) => {
	const [searchQuery, setSearchQuery] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [showResults, setShowResults] = useState(false);
	const searchContainerRef = useRef(null);
	const { newCanvas, updateBubble, syncBubbles } = useBubblesActions();

	const handleInput = (event) => {
		if (selectedId.length > 0 && event.target.value.length - 1 < searchQuery.length) {
			onBubbleSelect("");
			setSearchQuery("");
			return;
		}
		setShowResults(true);
		setSearchQuery(event.target.value);
	};


	const reloadField = () => {
		if (selectedId.length > 0) {
			searchBubbles({ searchTerm: selectedId }).then(r => {
				if (r.success) {
					if (r.results.length > 0) {
						setSearchQuery(parseName(r.results[0].name)[0]); // Update the state with the search results
						setShowResults(false);
					} else {
						setSearchQuery('! bubble not found !');
					}
				} else {
					setSearchQuery('! bubble not found !');
				}
			});
		}
		else {
			setSearchQuery('');
		}
	}
	useEffect(reloadField, [])
	useEffect(reloadField, [selectedId, reload])

	const handleSearchKeyDown = (event) => {
		if (event.key === 'Enter') {
			search();
		}
	};

	const createNew = () => {
		const newC = newCanvas({ onlyCreate: true });
		updateBubble({
			id: newC,
			properties: {
				name: JSON.stringify({ id: newC, type: "paragraph", props: {textColor:"default",backgroundColor:"default",textAlignment:"left"}, content: [{ type: 'text', text: searchQuery, styles: {} }], children: [] }),
			},
		}),
		setSearchResults([]);
		onBubbleSelect(newC);
		syncBubbles();
		reloadField();
	}

	const search = async () => {
		setSearchResults(['Loading...']);

		try {
			const data = await searchBubbles({ searchTerm: searchQuery });
			if (data.success) {
				if (data.results.length > 0) {
					setSearchResults(data.results); // Update the state with the search results
				} else {
					setSearchResults(['No data found']);
				}
			} else {
				setSearchResults(['No data found']);
			}
		} catch (error) {
			console.error('Search error:', error);
			setSearchResults(['No data found']);
		}
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				searchContainerRef.current &&
				!searchContainerRef.current.contains(event.target)
			) {
				// Clicked outside the search container
				setSearchResults([]); // Clear search results or hide the div as needed
			}
		}

		// Add event listener when the component mounts
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Remove event listener on cleanup
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchContainerRef]); // Empty array ensures this effect runs only on mount and unmount

	return (
		<div className={styles.search} ref={searchContainerRef}>
			<mantine.components.Generic.Form.TextInput
				name="title"
				icon={<RiSearchLine />}
				placeholder="Search bubble to reference..."
				onKeyDown={handleSearchKeyDown}
				onChange={handleInput}
				value={searchQuery}
				autoFocus={focus}
				className={"bn-text-input " + styles.search}
			/>
			{(selectedId.length > 0 && searchQuery != "! bubble not found !") && <Check style={{ color: "green", marginRight: '5px' }} />}
			{/* Search results container */}
			<div
				className={`${styles.searchResults} ${!showResults || (searchResults.length === 0 && (searchQuery.length == 0 || searchQuery == "! bubble not found !")) ? styles.empty : ''
					}`}
			>
				<div className={styles.searchResult} onClick={createNew}>
					<p key={`addBubble`}><span style={{ fontWeight: 'bold' }}>+</span> Create new bubble with title "{searchQuery}"</p>
				</div>
				{(searchResults.length === 1 &&
					searchResults[0] === 'Loading...') ||
					searchResults[0] === 'No data found'
					? searchResults.map((content, index) => (
						<p style={{ marginLeft: '10px' }} key={content}>
							{content}
						</p>
					))
					:
					searchResults.length == 0 ?
						<p style={{ marginLeft: '20px', fontStyle: 'italic' }} key={"pressEnter"}>
							Press enter to search
						</p> :
						// Pass setSearchResults to SearchResult
						searchResults.map((result) => (
							<SearchResult
								result={result}
								key={result._id}
								setSearchResults={setSearchResults}
								setSearchQuery={setSearchQuery}
								onClick={onBubbleSelect}
							/>
						))}
			</div>
		</div>
	);
};

