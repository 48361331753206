import React, { forwardRef, useRef, useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import { useDndMonitor, useDraggable, useDroppable, DragOverlay } from '@dnd-kit/core';
import { InlineDocument } from '../document/document';
import { useCombinedRefs } from '@dnd-kit/utilities';
import { ImSpinner4 } from "react-icons/im";
import { Maximize2, X } from 'lucide-react';


import NameEditor from './name-editor';
import Button from '../../common/button';

import { CANVAS_BUBBLE, DOCUMENT_BUBBLE } from '../../../types';

import styles from './canvas-bubble.module.sass';

import 'react-pdf/src/Page/TextLayer.css';
import 'react-pdf/src/Page/AnnotationLayer.css';

import {
	useBubblesActions,
	useBubblesState,
} from '../../../store/hooks/use-bubbles';
import { Resizable } from 'react-resizable';
import ErrorBoundary from '../../error-boundary';
import { getBubbleName, setBubbleHistory } from '../document/document-func';
import TitleLoader from '../title-loader';
import { handleNameGeneration } from '../../../api';
import NameEditorLoader from './name-editor-loader';
import { selectBubble } from '../../../store/bubbles.slice';
import { Document, Page, pdfjs } from 'react-pdf'
import { BASE_URL } from '../../../config';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const MaxAutoWidth = 500;
const MaxAutoHeight = 300;

// definitions:
// canvasIndex: the index of the bubble within the specific canvas array
// canvasParentId: the id of the bubble within whose canvas we are operating
let bubbleLocation;

const CanvasChildBubble = ({
	canvasIndex,
	canvasParentId,
	updateCount,
	id,
}) => {
	const { bubbles } = useBubblesState();
	const bubble = bubbles?.find((b) => b.id === id);

	return (
		<DraggableCanvasBubble
			canvasIndex={canvasIndex}
			canvasParentId={canvasParentId}
			updateCount={updateCount}
			bubble={bubble}
			attached
		/>
	);
};

const ChildBubbles = ({ parentBubble }) => {
	const { id: parentId, canvasChildren, expanded } = parentBubble;
	const { toggleExpanded } = useBubblesActions();

	if (!canvasChildren?.length) {
		return null;
	}

	return (
		<div className={classnames(styles.childBubbles, {
			[styles.expanded]: expanded,
			[styles.collapsed]: !expanded,
		})}>
			<button
				className={classnames(styles.childBubbleToggle, {
					[styles.expanded]: expanded,
				})}
				onClick={() => toggleExpanded({ id: parentId })}
			>
				<span>
					{expanded ? 'Hide' : 'Show'} inside ({canvasChildren.length})
				</span>
			</button>
			{expanded && (
				<div className={styles.childBubbleContainer}>
					{canvasChildren.map(({ id, updateCount }, i) => (
						<CanvasChildBubble
							canvasIndex={i}
							canvasParentId={parentId}
							updateCount={updateCount}
							key={`${id}-${i}`}
							id={id}
						/>
					))}
				</div>
			)}
		</div>
	);
};


  
  export const DraggableCanvasBubble = ({
	updateCount,
	canvasIndex,
	canvasParentId,
	bubble,
	location,
	dimensions,
	attached = false,
	isSelectedDragging = false,
	focusedCanvasBubbleId,
	canvasFocus,
  }) => {
	if (!bubble) return null;
  
	const { id } = bubble;
	const { selectedBubbles } = useBubblesState();
	const { moveCanvasChild, moveCanvasChildren, updateBubble } = useBubblesActions();
  
	const dragId = `${bubble.id}-${canvasIndex}`;
  
	const {
	  attributes,
	  listeners,
	  setNodeRef: setDraggableNodeRef,
	  isDragging,
	  transform,
	} = useDraggable({
	  id: dragId,
	  data: {
		type: CANVAS_BUBBLE,
		bubble,
		canvasIndex,
		canvasParentId,
		location,
		dimensions,
		id,
	  },
	});
  
	const {
	  setNodeRef: setDroppableNodeRef,
	  isOver,
	} = useDroppable({
	  id: dragId,
	  data: {
		accepts: [CANVAS_BUBBLE, DOCUMENT_BUBBLE],
		bubble,
	  },
	});
  
	const setNodeRef = useCombinedRefs(setDroppableNodeRef, setDraggableNodeRef);
  
	useDndMonitor({
	  onDragEnd: (event) => {
		if (isOver) {
		  const { active, over } = event;
		  if (over?.data.current.accepts.includes(active.data.current.type)) {
			const draggedBubble = active.data.current.bubble;
			if (draggedBubble.id !== bubble.id) {
			  const draggedCanvasIndex = active.data.current.canvasIndex;
  
			  if (selectedBubbles?.includes(draggedCanvasIndex)) {
				moveCanvasChildren({
				  canvasIndices: selectedBubbles,
				  fromBubbleId: active.data.current.canvasParentId,
				  toBubbleId: over.data.current.bubble.id,
				});
			  } else {
				moveCanvasChildren({
				  canvasIndices: [active.data.current.canvasIndex],
				  fromBubbleId: active.data.current.canvasParentId,
				  toBubbleId: over.data.current.bubble.id,
				});
			  }
			}
		  }
		}
	  },
	});
  
	const style = attached
	  ? null
	  : {
		  left: location.x,
		  top: location.y,
		  minWidth: dimensions.width,
		  minHeight: dimensions.height,
		  transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : null,
		};
  
	return (
	  <CanvasBubble
		updateCount={updateCount}
		canvasIndex={canvasIndex}
		canvasParentId={canvasParentId}
		ref={setNodeRef}
		{...listeners}
		{...attributes}
		style={style}
		bubble={bubble}
		attached={attached}
		dimensions={dimensions}
		isOver={isOver}
		focusedCanvasBubbleId={focusedCanvasBubbleId}
		canvasFocus={canvasFocus}
		className={classnames({
		  [styles.isDragging]: isDragging,
		})}
	  />
	);
  };

// Move these outside the CanvasBubble component to prevent recreation
const useStablePdfUrl = (pdfContent) => {
	const previousUrlRef = useRef(null);
	
	return useMemo(() => {
	  if (pdfContent === undefined || pdfContent === "loading") {
		return previousUrlRef.current;
	  }
	  
	  const newUrl = `${BASE_URL}/proxy/pdf?fileUrl=${pdfContent.split(';')[0] ?? pdfContent}`;
	  previousUrlRef.current = newUrl;
	  return newUrl;
	}, [pdfContent]);
  };
  
  const PdfViewer = React.memo(({ pdfUrl }) => {
	const [pdfDoc, setPdfDoc] = useState(null);
	const canvasRef = useRef();
  
	useEffect(() => {
	  if (!pdfUrl) return;
  
	  let mounted = true;
	  const loadingTask = pdfjs.getDocument(pdfUrl);
  
	  loadingTask.promise
		.then(pdf => mounted && setPdfDoc(pdf))
		.catch(err => console.error('PDF loading error:', err));
  
	  return () => {
		mounted = false;
		if (pdfDoc) {
		  pdfDoc.destroy();
		}
	  };
	}, [pdfUrl]);
  
	useEffect(() => {
	  if (pdfDoc && canvasRef.current) {
		pdfDoc.getPage(1).then(page => {
		  const viewport = page.getViewport({ scale: 1.0 });
		  const canvas = canvasRef.current;
		  const context = canvas.getContext('2d');
		  canvas.height = viewport.height;
		  canvas.width = viewport.width;
		  
		  page.render({
			canvasContext: context,
			viewport: viewport
		  });
		});
	  }
	}, [pdfDoc]);
  
	if (!pdfUrl || !pdfDoc) return <ImSpinner4 className={styles.spinner} />;
  
	return <canvas ref={canvasRef} className={styles.pdfEmbed} />;
  });

export const CanvasBubble = forwardRef(
  (
	{
	  updateCount,
	  canvasIndex,
	  canvasParentId,
	  className,
	  isOver,
	  attached = true,
	  dimensions,
	  bubble,
	  style,
	  offset,
	  focusedCanvasBubbleId,
	  canvasFocus,	
	  isDragging,
	  ...props
	},
	ref,
  ) => {
	const bubbleLocationRef = useRef(null);
	const {
	  stepIn,
	  updateBubble,
	  updateCanvasBubbleProperties,
	  notifyCanvasBubbles,
	  updateDocumentBubble,
	  syncBubbles,
	  selectBubble
	} = useBubblesActions();
	const { selectedBubbles, focusedBubbleId, focusedBubble, bubbles } = useBubblesState();
	const [isTitleLoading, setIsTitleLoading] = useState(false);
	const [hasResized, setHasResized] = useState(false);

	const pdfUrl = useStablePdfUrl(bubble.pdfContent);

	const handleClick = (e) => {
		if(document.querySelector(".bn-toolbar:hover")) return;
		e.stopPropagation();
		selectBubble({ 
		canvasIndex, 
		canvasParentId, 
		isMultiSelect: e.shiftKey // Pass shift key state
		});
	};

	useEffect(() => {
	  bubbleLocationRef.current = { x: style?.left, y: style?.top };
	}, [style]);

		const onStepIn = () => {
			stepIn({ id: bubble.id });
		};

	const onNameChange = (name) => {
	  if (name === bubble.name) return;
	  updateBubble({ id: bubble.id, properties: { name }, forceUpdate: true });
	  // notify about update, any duplicates can then refresh
	  notifyCanvasBubbles({ id: bubble.id, canvasParentId, canvasIndex });
	};

  const generateName = async () => {
	let content = [];
	let contentBubbles = [];
	setIsTitleLoading(true);
  
	const traverseBubbles = (bubble, depth = 0, visited = new Set()) => {
	  if (visited.has(bubble.id)) {
		return; 
	  }
  
	  visited.add(bubble.id);
  
	  if (depth <= 5) {
		for (let i = 0; i < bubble.canvasChildren.length; i++) {
		  const childId = bubble.canvasChildren[i];
		  const childBubble = bubbles.find((b) => b.id === childId.id);
		  if (childBubble) {
			contentBubbles.push(childBubble);
			traverseBubbles(childBubble, depth + 1, visited);
		  }
		}
	  }
  
	  for (let i = 0; i < bubble.documentChildren.length; i++) {
		const childId = bubble.documentChildren[i];
		const childBubble = bubbles.find((b) => b.id === childId);
		if (childBubble) {
		  contentBubbles.push(childBubble);
		}
	  }
	};
  
	traverseBubbles(bubble);
  
	for (let i = 0; i < contentBubbles.length; i++) {
	  const text = getBubbleName(contentBubbles[i]);
	  if (text) {
		content.push(text);
	  }
	}
  
	const name = await handleNameGeneration({ content });
	setIsTitleLoading(false);
  
	const updatedName = {
	  id: bubble.id,
	  type:'paragraph', 
	  props: {},
	  content: [{ type: "text", text: name, styles: {} }],
	};

	console.log(updatedName)
  
	updateBubble({ id: bubble.id, properties: { name: JSON.stringify(updatedName) }, forceUpdate:true });
	syncBubbles();
	window.dispatchEvent(new CustomEvent("TitleAICB", { detail: { name, id: bubble.id } }));
  };

	const updateHeightBeforeResize = (e) => {
	  if(!hasResized){
		setHasResized(true)
	  }
	  const bubbleLocation = bubbleLocationRef.current;
	  if (!bubbleLocation) {
		console.error('bubbleLocation is not defined');
		return;
	  }
	  const MIN_HEIGHT = 100;
	  const MIN_WIDTH = 300;
	  const updatedHeight = Math.max((e.clientY-80) - bubbleLocation.y, MIN_HEIGHT);


			updateCanvasBubbleProperties({
				id: bubble.id,
				canvasIndex,
				canvasParentId,
				canvasProperties: {
					dimensions: { ...dimensions, height: updatedHeight },
				},
			});
		};



		const isSelected =
			canvasParentId === focusedBubbleId &&
			selectedBubbles.includes(canvasIndex);

		if (offset) {
			style = {
				...style,
				left: offset.x,
				top: offset.y,
				position: 'absolute',
			};
		}

		const MIN_WIDTH = 300;
		const MIN_HEIGHT = 100;

		if (dimensions) {
			style = {
				...style,
				minWidth: Math.max(MIN_WIDTH, dimensions.width),
				minHeight: Math.max(MIN_HEIGHT, dimensions.height),
			};
		}

	const resizeBubble = (_, { size }) => {
	  updateCanvasBubbleProperties({
		id: bubble.id,
		canvasIndex,
		canvasParentId,
		canvasProperties: {
		  dimensions: {
			width: size.width,
			height: size.height
		  },
		},
	  });
	};

		const enforceMinHeightOnResizeStop = (_, { size }) => {
			const MIN_WIDTH = 300; 
			const updatedWidth = Math.max(size.width, MIN_WIDTH);
		
			updateCanvasBubbleProperties({
				id: bubble.id,
				canvasIndex,
				canvasParentId,
				canvasProperties: {
					dimensions: {
						width: updatedWidth, 
						height:size.height
					},
				},
			});
		};
		

		return (
			<ErrorBoundary>
			  <Resizable
				width={dimensions?.width}
				height={dimensions?.height}
				resizeHandles={attached ? [] : ['se']}
				handle={<div className={styles.resizeToggle}><i className={classnames(styles.icon, styles.iconLink)} /></div>}
				onResizeStart={updateHeightBeforeResize}
				onResize={resizeBubble}
				onResizeStop={enforceMinHeightOnResizeStop}
			  >
				<div
				  className={classnames(className, styles.canvasBubble, {
					[styles.highlighted]: isOver || isSelected,
					[styles.attached]: attached,
					[styles.imageContent]: bubble.imageContent,
				  })}
				  style={style}
				  ref={ref}
				  onClick={handleClick}
				  onDoubleClick={(e) => e.stopPropagation()}
				>
				  <div className={`${styles.header} header-name`} {...props}>
					{!isTitleLoading ? (
					  <NameEditor
						bubbleId={bubble.id}
						initialName={bubble.name}
						onNameChange={onNameChange}
						updateCount={updateCount}
						sizeExpander={resizeBubble}
						dimensions={dimensions}
						MaxAutoWidth={MaxAutoWidth}
						focusedCanvasBubbleId={focusedCanvasBubbleId}
						hasResized={hasResized}
						canvasFocus={canvasFocus}
					  />
					) : (
					  <NameEditorLoader />
					)}
					<div className={`${styles.buttons} buttons`}>
					  <Button onClick={(e) => { e.stopPropagation(); generateName(); }} icon="✨" size="small" />
					  <Button onClick={(e) => { e.stopPropagation(); onStepIn(); }} icon={<Maximize2 size={12} />} size="small" />
					</div>
				  </div>
				  <div className={styles.wrapper}>
					{isDragging ? (
					  <div className={styles.draggingPlaceholder}></div> // Empty placeholder during drag
					) : bubble.pdfContent ? (
					  pdfUrl !== "loading" ? (
						<PdfViewer pdfUrl={pdfUrl} />
					  ) : (
						<ImSpinner4 className={styles.spinner} />
					  )
					) : bubble.imageContent ? (
					  bubble.imageContent !== "loading" ? (
						<img src={bubble.imageContent} />
					  ) : (
						<ImSpinner4 className={styles.spinner} />
					  )
					) : (
					  <InlineDocument
						updateCount={updateCount}
						canvasParentId={canvasParentId}
						parentCanvasIndex={canvasIndex}
						className={`${styles.document} canvasDocument`}
						bubble={bubble}
						dimensions={dimensions}
						sizeExpander={resizeBubble}
						MaxAutoWidth={MaxAutoWidth}
						MaxAutoHeight={MaxAutoHeight}
						focusedCanvasBubbleId={focusedCanvasBubbleId}
						hasResized={hasResized}
						canvasFocus={canvasFocus}
					  />
					)}
					<ChildBubbles parentBubble={bubble} />
				  </div>
				</div>
			  </Resizable>
			</ErrorBoundary>
		  );
	},
);


CanvasBubble.displayName = 'CanvasBubble';

export default CanvasBubble;
