import React, { useEffect, useRef, useState } from 'react';

import styles from './name-editor.module.sass';

import {
  BlockNoteSchema,
  defaultBlockSpecs,q0
} from "@blocknote/core";
import { useCreateBlockNote } from '@blocknote/react';
import { BlockNoteView } from '@blocknote/mantine';
import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";
import { getBubbleName, setBubbleHistory } from '../document/document-func';
import { useBubblesState } from '../../../store/hooks/use-bubbles';

const nameSchema = BlockNoteSchema.create({
  blockSpecs: {
    // Adds all default blocks.
    ...defaultBlockSpecs,
    // Adds the Alert block.
    image: undefined,
    file: undefined,
    video: undefined,
    audio: undefined
  },
});

export function NameEditor({ 
  bubbleId,
  initialName,
  onNameChange,
  updateCount,
  sizeExpander,
  dimensions,
  MaxAutoWidth,
  focusedCanvasBubbleId,
  canvasFocus,
}) {

  const ref = useRef(null);

  const editor = useCreateBlockNote({schema: nameSchema, trailingBlock: false, sideMenuDetection:'editor'})
  const [prevIN, setPrevIN] = useState("");
  
  const onChange = () => {
    if(editor.prosemirrorView.hasFocus() && canvasFocus != undefined){
      console.log("focused name")
      canvasFocus(bubbleId)
    }
    if(isCanvasBubbleHeader() && editor.prosemirrorView.hasFocus()){
      if(dimensions.width - 46 < editor.prosemirrorView.dom.scrollWidth && dimensions.width <= MaxAutoWidth){
        sizeExpander(_,{size:{width: editor.prosemirrorView.dom.scrollWidth +46, height:dimensions.height}})

      }
      if (dimensions.width >= MaxAutoWidth){
        editor.prosemirrorView.dom.style.whiteSpace = "normal"
      }
    }
    
    if(!editor.prosemirrorView.hasFocus()){ 
      editor.prosemirrorView.dom.style.whiteSpace = "normal"
    }

    if (editor.document.length > 1) {
      let res = editor.document.reduce((toRem, b) => { toRem.push(b.id); return toRem; }, []);
      res.shift()
      editor.removeBlocks(res);
    }
    setPrevIN(JSON.stringify(editor.document[0]))
    onNameChange(JSON.stringify(editor.document[0]))
  }

  const isCanvasBubbleHeader = ()=>{
    const parentClasses = ref.current.parentElement.className
    return parentClasses.includes("canvas-bubble-module")
  } 

  useEffect(() => {
    try {
      if (editor.document.length > 0 && prevIN != initialName) {
        let res = editor.document.reduce((toRem, b) => { toRem.push(b.id); return toRem; }, []);
        if(initialName.length > 0){
          editor.replaceBlocks(res, [JSON.parse(initialName)])
        }
        else{
          editor.replaceBlocks(res, [{type: "paragraph", content: "",}])
        }
        
      }
    } catch (ex) { }

  }, [updateCount])

  useEffect(() => {
    try {
      if (editor.document.length > 0) {
        let res = editor.document.reduce((toRem, b) => { toRem.push(b.id); return toRem; }, []);
        if(initialName.length > 0){
          editor.replaceBlocks(res, [JSON.parse(initialName)])
        }
        else{
          editor.replaceBlocks(res, [{type: "paragraph", content: "",}])
        }
      }
    } catch (ex) { }

  }, [bubbleId])

  useEffect(()=>{
    if(!isCanvasBubbleHeader()){
      window.addEventListener("TitleAI", (e)=>{
        try {
          if (editor.document.length > 0) {
            let res = editor.document.reduce((toRem, b) => { toRem.push(b.id); return toRem; }, []);
            editor.replaceBlocks(res, [{type: "paragraph", content: e.detail,}])
          }
        } catch (ex) { }
      })
    } 
    else{
      window.addEventListener("TitleAICB", (e)=>{
        if(e.detail.id != bubbleId) return;
        try {
          if (editor.document.length > 0) {
            let res = editor.document.reduce((toRem, b) => { toRem.push(b.id); return toRem; }, []);
            editor.replaceBlocks(res, [{type: "paragraph", content: e.detail.name}])
          }
        } catch (ex) { }
      })
    } 
  }, []);

  useEffect(()=>{
    if(dimensions && editor.prosemirrorView && isCanvasBubbleHeader()){
      editor.prosemirrorView.dom.style.whiteSpace = "nowrap"
      if(dimensions.width - 46 < editor.prosemirrorView.dom.scrollWidth){
        editor.prosemirrorView.dom.style.whiteSpace = "normal"
      }
    }
  },[dimensions])

  useEffect(()=>{
    if(editor.prosemirrorView != undefined){
      try{
        editor.prosemirrorView.dom.style.whiteSpace = "nowrap"
        if(dimensions.width - 46 < editor.prosemirrorView.dom.scrollWidth){
          editor.prosemirrorView.dom.style.whiteSpace = "normal"
        }
      }catch(e){}
    }
  }, [editor.prosemirrorView])
  
  return (
    <div ref = {ref} className={`${styles.editor} name-editor`}>
      <BlockNoteView
        editor={editor}
        theme={'light'}
        sideMenu={false}
        formattingToolbar={false}
        linkToolbar={false}
        filePanel={false}
        slashMenu={false}
        tableHandles={false}
        onChange={onChange}
      ></BlockNoteView>
    </div>
  );
}

export default NameEditor;
