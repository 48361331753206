import axios from 'axios';
import queryString from 'query-string';

axios.defaults.withCredentials = true;

import { BASE_URL } from './config';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const handleTextGeneration = async ({ promptText, content }) => {
  try {
    if (content) {
      const response = await fetch(`${BASE_URL}/prompt-with-content`, {
        body: JSON.stringify({
          promptText,
          content,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });
      return response.body.getReader();
    } else {
      const response = await fetch(
        `${BASE_URL}/search-new?query=${promptText}`,
      );

      return response.body.getReader();
    }
  } catch (error) {
    console.error('Error fetching data from API:', error);
    return null;
  }
};

export const handleNameGeneration = async ({ content }) => {
  try {
    if (content.length > 0) {
      const response = await fetch(`${BASE_URL}/generate-name-for-bubble`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content }),
      });

      const reader = response.body.getReader();
      let result = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += new TextDecoder().decode(value);
      }
      return result.trim();
    }
  } catch (error) {
    console.error('Error fetching data from API:', error);
    return null;
  }
};


export const handleImageGeneration = async ({ promptText }) => {
  const result = await axios.post(`${BASE_URL}/imagegeneration`, {
    searchInput: promptText,
  });
  return result.data ?? null;
};

export const handleImageUpload = async ({ imageContent }) => {
  const result = await axios.post(`${BASE_URL}/imageupload`, {
    image: imageContent,
  });
  return result.data ?? null;
};

export const handlePDFUpload = async ({ imageContent }) => {
  try {
      const response = await fetch(`${BASE_URL}/imageupload`, {
        body: JSON.stringify({
          image: imageContent,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });
      return response.body.getReader();
  } catch (error) {
    console.error('Error fetching data from API:', error);
    return null;
  }
};

export const loadUserData = () =>
  axios.get(`${BASE_URL}/auth/user`).then((response) => response.data);

export const loadScratchboardId = () =>
  axios.get(`${BASE_URL}/api/bubbles/scratchpadId`).then((response) => response.data);

export const loadBubbles = async ({ focusedBubbleId }) => {
  const query = queryString.stringify({ focusedBubbleId });
  return axios
    .get(`${BASE_URL}/api/bubbles?${query}`)
    .then((response) => response.data);
};

export const syncBubbles = ({ bubbles, focusedBubbleId }) =>
  axios
    .put(`${BASE_URL}/api/bubbles`, {
      bubbles,
      focusedBubbleId,
    })
    .then((response) => response.data);

export const searchBubbles = async ({ searchTerm }) => {
  const query = queryString.stringify({ searchTerm });
  return axios
    .get(`${BASE_URL}/api/bubbles/search?${query}`)
    .then((response) => response.data);
};

export const savePromptToDatabase = async (promptText) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/bubbles/save-prompt`, {
      promptText,
    });
    return response.data;
  } catch (error) {
    console.error('Error saving prompt to database:', error);
    throw error;
  }
};

export const fetchLatestPrompts = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/bubbles/latest-prompts`);
    return response.data;
  } catch (error) {
    console.error('Error fetching latest prompts:', error);
    throw error;
  }
};

export const validateRegistartionCode = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/auth/validate-code`);
    return response.data;
  } catch (error) {
    console.error('Error validating registration code:', error);
    throw error;
  }
};

export const deleteBubbles = async ({ selectedBubble }) => {
  const query = selectedBubble
    .map((selectedBubble) => `bubble_id=${selectedBubble.id}`)
    .join('&');
  return axios
    .delete(`${BASE_URL}/api/bubbles?${query}`)
    .then((response) => response.data);
};

export const updateLastViewedBubbles = async (ids, bubbleTexts, updatedAts) => {
  try {
    const response = await axios.post(`${BASE_URL}/update-history`, {
      ids, bubbleTexts, updatedAts
    });
    return response.data;
  } catch (error) {
    console.error('Error updating Last Viewed Bubbles:', error);
    throw error;
  }
}
export const getLastViewedBubbles = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/get-history`);
    return response.data;
  } catch (error) {
    console.error('Error get Last Viewed Bubbles:', error);
    throw error;
  }
}

export const deleteFromLastViewedBubbles = async (ids) => {
  try {
    const response = await axios.delete(`${BASE_URL}/delete-from-history`, {
      data: { ids },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting from LastViewedBubbles:', error);
    throw error;
  }
};

export const getBreadcrumb = async (id) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/bubbles/get-parents-for-breadcrumb`, {
      id: id
    });
    return response.data;
  } catch (error) {
    console.error('Error getting parents for breadcrumb:', error);
    throw error;
  }
}

export const getBubbleById = async (id) =>{
  try {
    const response = await axios.get(`${BASE_URL}/api/bubbles/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error getting document bubble:', error);
    throw error;
  }
}


export default {
  handleTextGeneration,
  handleImageGeneration,
  handlePDFUpload,
  handleImageUpload,
  loadUserData,
  loadBubbles,
  syncBubbles,
  searchBubbles,
  savePromptToDatabase,
  fetchLatestPrompts,
  deleteBubbles,
  validateRegistartionCode,
  updateLastViewedBubbles,
  getBreadcrumb,
  getBubbleById,
  deleteFromLastViewedBubbles
};
