import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import appReducer from './app.slice';
import bubblesReducer from './bubbles.slice';
import { historyReducer } from './history.reducer';

const persistConfig = {
  key: 'bubbles',
  storage,
};

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    savePreviousLocations: 300,
    debug: true,
  });

const reducers = combineReducers({
  app: appReducer,
  bubbleData: bubblesReducer,
  router: routerReducer,
  historyStack: historyReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(routerMiddleware),
});

export const history = createReduxHistory(store);
export const getRouterIndex = (state) => state.router.index || 0;
export const getRouterLength = (state) => state.router.entries?.length || 1;

export default store;