import { useState } from 'react';
import { useBubblesState, useBubblesActions } from '../store/hooks/use-bubbles';
import { useAppState } from '../store/hooks/use-app';

import useInterval from './use-interval';

// autosync check interval
const SYNC_CHECK_INTERVAL = 1000 * 5; // 5 seconds

const useSync = () => {
  const [lastSync, setLastSync] = useState(null);
  const { loggedIn } = useAppState();
  const { bubbles } = useBubblesState();
  const { syncBubbles } = useBubblesActions();

  useInterval(() => {
    if (loggedIn) {
      if (!lastSync || bubbles.some((b) => {
			return b.lastSyncedAt==undefined || b.updatedAt > b.lastSyncedAt  ;
		})){
        console.log('Sync not done or bubble updated, syncing...');

        /*bubbles.forEach((bubble) => {
          if (bubble.updatedAt > lastSync || !bubble.updatedAt) {
            console.log('Unsynced bubble', bubble);
            if (!bubble.updatedAt) {
              console.log('New bubble, adding...');
            } else {
              console.log('lastSync', lastSync);
            }
          }
        });*/
        syncBubbles();
        setLastSync(Date.now());
      }
    }
  }, SYNC_CHECK_INTERVAL);
};

export default useSync;
